interface TextAreaProps {
  id: string;
  label?: string;
  labelColour?: string;
  ariaLabel?: string;
  textColour?: string;
  bgColour?: string;
  borderColour?: string;
  placeholderColour?: string;
  placeholder?: string;
  autoComplete?: string;
  required?: boolean;
  children?: React.ReactNode;
  showLabel?: boolean;
  onChange?: (e: any) => void;
  disabled?: boolean;
  small?: string | null;
  widthClass?: string;
  rows?: number;
  value?: string;
}

export default function TextArea({
  id,
  label,
  labelColour = "text-black dark:text-white",
  ariaLabel = "",
  textColour = "black dark:text-white",
  bgColour = "light dark:bg-black-light",
  borderColour = "light/50",
  placeholderColour = "light/50",
  placeholder = "",
  autoComplete = "",
  required = true,
  children,
  showLabel = false,
  onChange = () => { },
  disabled = false,
  small = null,
  widthClass = "w-full",
  rows = 3,
  value = "",
}: TextAreaProps) {
  return (
    <div className={widthClass}>
      {showLabel ? (
        <label
          htmlFor={id}
          className={`mb-3 block text-left text-sm font-medium ${labelColour}`}
        >
          {label}
        </label>
      ) : (
        <label
          htmlFor={id}
          className={`hidden block text-left text-sm font-medium ${labelColour}`}
        />
      )}
      {disabled ? (
        <textarea
          id={id}
          className={`block w-full rounded-md bg-${bgColour} py-1.5 pl-3 pr-10 text-base text-${textColour} placeholder:text-${placeholderColour} border-1 border-${borderColour} focus:border-2 focus:border-danger ring-0 focus:ring-0 outline-0 sm:pr-9 sm:text-sm/6`}
          placeholder={placeholder}
          aria-label={ariaLabel}
          autoComplete={autoComplete}
          required={required}
          onChange={onChange}
          rows={rows}
          disabled
          value={value}
        >
          {children}
        </textarea>
      ) : (
        <textarea
          id={id}
          className={`block w-full rounded-md bg-${bgColour} py-1.5 pl-3 pr-10 text-base text-${textColour} placeholder:text-${placeholderColour} border-1 border-${borderColour} focus:border-2 focus:border-danger ring-0 focus:ring-0 outline-0 sm:pr-9 sm:text-sm/6`}
          placeholder={placeholder}
          aria-label={ariaLabel}
          autoComplete={autoComplete}
          required={required}
          onChange={onChange}
          rows={rows}
          value={value}
        >
          {children}
        </textarea>
      )}
      {small !== null && (
        <p className="text-xs text-left text-secondary/70 mt-2">{small}</p>
      )}
    </div>
  );
}
