import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

export interface UserState {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  username: string;
  auth: {
    groups: [string, string];
    permissions: [string, string];
  };
  profile: {
    id: string;
    created_date: string;
    updated_date: string | null;
    date_of_birth: string | null;
    is_verified: boolean;
    is_external_account: boolean;
    profile_picture: string | null;
    cover_picture: string | null;
    bio: string | null;
    tagline: string;
    groups: Array<[string, string]>;
    num_posts: number;
    num_comments: number;
    num_upvotes: number;
    num_notifications: number;
  };
}

// Define a type for the slice state
export interface AuthState {
  access: string | null;
  refresh: string | null;
  isAuthenticated: boolean;
  waiting: boolean;
  loading: boolean;
  user: UserState | null;
  email: {
    verification: {
      loading: boolean;
      sent: boolean;
    };
    referral: {
      loading: boolean;
      sent: boolean;
    };
    contact: {
      loading: boolean;
      sent: boolean;
    };
    recovery: {
      loading: boolean;
      verified: boolean;
      complete: boolean;
    };
  };
  api_key: {
    loading: boolean;
    key: string | null;
  };
}

// Define the initial state using that type
const initialState: AuthState = {
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: false,
  waiting: false,
  loading: true,
  user: null,
  email: {
    verification: {
      loading: false,
      sent: false,
    },
    referral: {
      loading: false,
      sent: false,
    },
    contact: {
      loading: false,
      sent: false,
    },
    recovery: {
      loading: false,
      verified: false,
      complete: false,
    },
  },
  api_key: {
    loading: false,
    key: null,
  },
};

//////////////////////////////
//////// Async Thunks ////////
//////////////////////////////

// Get auth
export const getAuth = createAsyncThunk(
  "auth/get",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = { headers: { Authorization: `Bearer ${auth.access}` } };

      // Retrieve auth data
      let res = await axios.get(`/api/auth`, config);
      if (res.status === 200) {
        return { data: res.data, status: res.status };
      }

      // If not successful, try to refresh credentials
      const refreshConfig = { headers: { "Content-Type": "application/json" } };
      const body = { refresh: localStorage.refresh };
      res = await axios.post(`/api/auth/login/refresh`, body, refreshConfig);

      // Store new access token
      localStorage.setItem("access", res.data.access);

      // Try to get auth again
      const newConfig = {
        headers: { Authorization: `Bearer ${res.data.access}` },
      };
      res = await axios.get(`/api/auth`, newConfig);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Login
export const login = createAsyncThunk(
  "auth/post/login",
  async (
    obj: { username: string; password: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const { username, password } = obj;
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({ username, password });
      const res = await axios.post(`/api/auth/login`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Logout
export const logout = createAsyncThunk(
  "auth/post/logout",
  async (arg, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth.access}`,
        },
      };
      const res = await axios.post(`/api/auth/logout`, null, config);

      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      return { data: res.data, status: res.status };
    } catch (err: any) {
      console.log(err);
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Register
export const register = createAsyncThunk(
  "auth/post/register",
  async (
    obj: {
      email: string;
      username: string;
      currentPassword: string;
      confirmPassword: string;
      referralId: string | null;
      over18: boolean;
      agreeTerms: boolean;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({
        first_name: "",
        last_name: "",
        date_of_birth: null,
        email: obj.email,
        username: obj.username,
        current_password: obj.currentPassword,
        confirm_password: obj.confirmPassword,
        update_password: "",
        bio: null,
        timezone: "GB",
        language: "EN",
        agree_terms: obj.agreeTerms,
        over_18: obj.over18,
      });

      let endpoint = `/api/auth/register`;
      if (obj.referralId !== null && obj.referralId.length > 0)
        endpoint += `?referral_id=${obj.referralId}`;
      const res = await axios.post(endpoint, body, config);

      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

// Update Profile
export const updateProfile = createAsyncThunk(
  "auth/post/update-profile",
  async (
    obj: {
      firstName: string;
      lastName: string;
      email: string;
      username: string;
      currentPassword: string;
      updatePassword: string;
      confirmPassword: string;
      dateOfBirth: string | null;
      profilePicture: { id: string; source: string; data: string } | null;
      coverPicture: { id: string; source: string; data: string } | null;
      tagline: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        first_name: obj.firstName,
        last_name: obj.lastName,
        email: obj.email,
        username: obj.username,
        current_password: obj.currentPassword,
        update_password: obj.updatePassword,
        confirm_password: obj.confirmPassword,
        date_of_birth: obj.dateOfBirth,
        profile_picture: obj.profilePicture,
        cover_picture: obj.coverPicture,
        tagline: obj.tagline,
        bio: auth.user?.profile.bio,
      });

      const res = await axios.put(`/api/auth`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err);
      }
    }
  }
);

// Send verification email
export const sendVerificationEmail = createAsyncThunk(
  "auth/post/verification/email",
  async (obj: { email: string }, { getState, rejectWithValue }) => {
    try {
      const { email } = obj;
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({ email });
      const res = await axios.post(
        `/api/auth/verification`,
        body,
        config
      );
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Confirm account verification
export const confirmAccountVerification = createAsyncThunk(
  "auth/get/verification/confirm",
  async (
    obj: {
      profileId: string | undefined;
      referralId: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      let endpoint = `/api/auth/verification/confirm/${obj.profileId}`;
      if (obj.referralId !== null && obj.referralId.length > 0)
        endpoint += `?referral_id=${obj.referralId}`;
      const res = await axios.get(endpoint);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Send referral email
export const sendReferralEmail = createAsyncThunk(
  "auth/post/referral/email",
  async (
    obj: { email: string },
    { getState, rejectWithValue }
  ) => {
    try {

      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const { email } = obj;
      const body = JSON.stringify({ email });

      const res = await axios.post(`/api/auth/referral`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Generate new API key
export const generateAPIKey = createAsyncThunk(
  "auth/post/api-key",
  async (args, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      let endpoint = `/api/auth/api-key`;
      const res = await axios.post(endpoint, null, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Delete account
export const deleteAccountPermanently = createAsyncThunk(
  "auth/delete/account",
  async (args, { getState, rejectWithValue }) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const res = await axios.delete(`/api/auth`, {
        headers: { Authorization: `Bearer ${auth.access}` },
      });
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue({
          data: err.response.data,
          status: err.response.status,
        });
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Send contact us email
export const sendContactUsEmail = createAsyncThunk(
  "auth/post/contact-us/email",
  async (
    obj: {
      email: string;
      subject: string;
      message: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Configure authorization header with user's token
      const config = { headers: { "Content-Type": "application/json" } };

      const { email, subject, message } = obj;
      const body = JSON.stringify({ email, subject, message });

      const res = await axios.post(
        `/api/auth/contact/`,
        body,
        config
      );
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Forgot my password
export const submitEmailToRecoverPassword = createAsyncThunk(
  "auth/post/forgot-my-password",
  async (obj: { email: string }, { getState, rejectWithValue }) => {
    try {
      const { email } = obj;
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({ email });

      let endpoint = `/api/auth/forgot-my-password`;
      const res = await axios.post(endpoint, body, config);

      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Verify password recovery session
export const verifyPasswordRecoverySession = createAsyncThunk(
  "auth/forgot-my-password/recovery/get",
  async (
    obj: { profileId: string | null; sessionId: string | null },
    { getState, rejectWithValue }
  ) => {
    try {
      // Verify profile ID and session ID
      const config = {};
      let res = await axios.get(
        `/api/auth/forgot-my-password/verify?profile=${obj.profileId}&session=${obj.sessionId}`,
        config
      );
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Update password by recovery
export const updatePasswordByRecovery = createAsyncThunk(
  "auth/post/forgot-my-password/recovery",
  async (
    obj: {
      profileId: string | null;
      password: string;
      confirmPassword: string;
      sessionId: string | null;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const { profileId, password, confirmPassword, sessionId } = obj;
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({
        profile: profileId,
        new_password: password,
        confirm_password: confirmPassword,
        session: sessionId,
      });

      let endpoint = `/api/auth/forgot-my-password/recover`;
      const res = await axios.post(endpoint, body, config);

      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Toggle favourite location
export const toggleFavouriteLocation = createAsyncThunk(
  "auth/location/favourite/put",
  async (
    obj: {
      locationId: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        id: obj.locationId
      });

      const res = await axios.put(`/api/venue/location/favourite`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Send Google OAuth token to server
export const sendGoogleOAuthToken = createAsyncThunk(
  "auth/google/login/token",
  async (
    obj: { token: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const { token } = obj;
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({ token });

      const res = await axios.post(`/api/auth/google/login/token`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Login authenticate Google user with token
export const authenticateGoogleUser = createAsyncThunk(
  "auth/google/login/callback",
  async (
    obj: { token: string },
    { getState, rejectWithValue }
  ) => {
    try {
      const { token } = obj;
      const config = { headers: { "Content-Type": "application/json" } };
      const body = JSON.stringify({ token });

      const res = await axios.post(`/api/auth/google/login/callback`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

// Toggle Group membership (Join/Leave)
export const toggleGroupMembership = createAsyncThunk(
  "auth/group/membership/toggle",
  async (
    obj: {
      groupId: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      // Get user data from store
      const { auth } = getState() as { auth: AuthState };

      // Configure authorization header with user's token
      const config = {
        headers: {
          Authorization: `Bearer ${auth.access}`,
          "Content-Type": "application/json",
        },
      };

      const body = JSON.stringify({
        id: obj.groupId,
      });

      const res = await axios.put(`/api/interest/group/membership`, body, config);
      return { data: res.data, status: res.status };
    } catch (err: any) {
      if (err.response && err.response.data.message) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    }
  }
);

/////////////////////////////
//////// Redux slice ////////
/////////////////////////////

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAPIKeyObject: {
      prepare() {
        return { payload: {} };
      },
      reducer(state, action: PayloadAction<{}>) {
        state.api_key.loading = false;
        state.api_key.key = null;
      },
    },
  },
  extraReducers: (builder) => {
    builder

      // Get Auth
      .addCase(getAuth.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAuth.fulfilled, (state, action) => {
        state.isAuthenticated = true;
        state.loading = false;
        state.user = action.payload.data;
      })
      .addCase(getAuth.rejected, (state) => {
        state.loading = false;
        state.user = null;
        state.access = null;
        state.refresh = null;
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
      })

      // Login
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.access = action.payload.data.access;
        state.refresh = action.payload.data.refresh;
        state.isAuthenticated = true;

        // Set auth tokens in local storage
        localStorage.setItem("access", action.payload.data.access);
        localStorage.setItem("refresh", action.payload.data.refresh);
      })
      .addCase(login.rejected, (state) => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        state.user = null;
        state.access = null;
        state.refresh = null;
        state.isAuthenticated = false;
        state.loading = false;
      })

      // Logout
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.waiting = true;
      })
      .addCase(logout.fulfilled, (state) => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        state.user = null;
        state.access = null;
        state.refresh = null;
        state.isAuthenticated = false;
        state.loading = false;
        state.waiting = false;
      })
      .addCase(logout.rejected, (state) => {
        return state;
      })

      // Register
      .addCase(register.pending, (state) => {
        state.loading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(register.rejected, (state) => {
        state.loading = false;
        state.isAuthenticated = false;
      })

      // Update Profile
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.loading = false;
      })
      .addCase(updateProfile.rejected, (state) => {
        state.loading = false;
      })

      // Send verification email
      .addCase(sendVerificationEmail.pending, (state) => {
        state.email.verification.loading = true;
        state.email.verification.sent = false;
      })
      .addCase(sendVerificationEmail.fulfilled, (state, action) => {
        state.email.verification.loading = false;
        state.email.verification.sent = true;
      })
      .addCase(sendVerificationEmail.rejected, (state) => {
        state.email.verification.loading = false;
        state.email.verification.sent = false;
      })

      // Confirm account verification
      .addCase(confirmAccountVerification.pending, (state) => {
        state.loading = true;
      })
      .addCase(confirmAccountVerification.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.isAuthenticated = false;
        state.loading = false;
      })
      .addCase(confirmAccountVerification.rejected, (state) => {
        state.loading = false;
      })
      // Send referral email
      .addCase(sendReferralEmail.pending, (state) => {
        state.email.referral.loading = true;
        state.email.referral.sent = false;
      })
      .addCase(sendReferralEmail.fulfilled, (state, action) => {
        state.email.referral.loading = false;
        state.email.referral.sent = true;
      })
      .addCase(sendReferralEmail.rejected, (state) => {
        state.email.referral.loading = false;
        state.email.referral.sent = false;
      })

      // Generate new API key
      .addCase(generateAPIKey.pending, (state) => {
        state.api_key.loading = true;
      })
      .addCase(generateAPIKey.fulfilled, (state, action) => {
        state.api_key.loading = false;
        state.api_key.key = action.payload.data.key;
      })
      .addCase(generateAPIKey.rejected, (state) => {
        state.api_key.loading = false;
        state.api_key.key = null;
      })

      // Delete account permanently
      .addCase(deleteAccountPermanently.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAccountPermanently.fulfilled, (state, action) => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        state.user = null;
        state.access = null;
        state.refresh = null;
        state.isAuthenticated = false;
        state.loading = false;
      })
      .addCase(deleteAccountPermanently.rejected, (state) => {
        state.loading = false;
      })

      // Send contact us email
      .addCase(sendContactUsEmail.pending, (state) => {
        state.email.contact.loading = true;
        state.email.contact.sent = false;
      })
      .addCase(sendContactUsEmail.fulfilled, (state, action) => {
        state.email.contact.loading = false;
        state.email.contact.sent = true;
      })
      .addCase(sendContactUsEmail.rejected, (state) => {
        state.email.contact.loading = false;
        state.email.contact.sent = false;
      })

      // Forgot my password
      .addCase(submitEmailToRecoverPassword.pending, (state) => {
        state.email.recovery.loading = true;
      })
      .addCase(submitEmailToRecoverPassword.fulfilled, (state, action) => {
        state.email.recovery.loading = false;
      })
      .addCase(submitEmailToRecoverPassword.rejected, (state) => {
        state.email.recovery.loading = false;
      })

      // Verify password recovery session
      .addCase(verifyPasswordRecoverySession.pending, (state) => {
        state.email.recovery.loading = true;
        state.email.recovery.verified = false;
      })

      .addCase(verifyPasswordRecoverySession.fulfilled, (state, action) => {
        state.email.recovery.loading = false;
        state.email.recovery.verified = true;
      })

      .addCase(verifyPasswordRecoverySession.rejected, (state) => {
        state.email.recovery.loading = false;
        state.email.recovery.verified = false;
      })

      // Update password by recovery
      .addCase(updatePasswordByRecovery.pending, (state) => {
        state.email.recovery.loading = true;
        state.email.recovery.complete = false;
      })

      .addCase(updatePasswordByRecovery.fulfilled, (state, action) => {
        state.email.recovery.loading = false;
        state.email.recovery.complete = true;
      })

      .addCase(updatePasswordByRecovery.rejected, (state, action) => {
        state.email.recovery.loading = false;
        state.email.recovery.complete = false;
      })

      // Toggle favourite location
      .addCase(toggleFavouriteLocation.pending, (state) => {
      })
      .addCase(toggleFavouriteLocation.fulfilled, (state, action) => {
        state.user = action.payload.data;
      })
      .addCase(toggleFavouriteLocation.rejected, (state) => {
      })

      // Send Google OAuth token to server
      .addCase(sendGoogleOAuthToken.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendGoogleOAuthToken.fulfilled, (state, action) => {
        state.loading = true;
      })
      .addCase(sendGoogleOAuthToken.rejected, (state) => {
        state.loading = false;
      })

      // Login authenticate Google user with token
      .addCase(authenticateGoogleUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(authenticateGoogleUser.fulfilled, (state, action) => {
        state.loading = false;
        state.access = action.payload.data.access;
        state.refresh = action.payload.data.refresh;
        state.isAuthenticated = true;

        // Set auth tokens in local storage
        localStorage.setItem("access", action.payload.data.access);
        localStorage.setItem("refresh", action.payload.data.refresh);
      })
      .addCase(authenticateGoogleUser.rejected, (state) => {
        localStorage.removeItem("access");
        localStorage.removeItem("refresh");
        state.user = null;
        state.access = null;
        state.refresh = null;
        state.isAuthenticated = false;
        state.loading = false;
      })

      // Toggle Group membership (Join/Leave)
      .addCase(toggleGroupMembership.pending, (state) => {
        state.waiting = true;
      })
      .addCase(toggleGroupMembership.fulfilled, (state, action) => {
        state.user = action.payload.data;
        state.waiting = false;
      })
      .addCase(toggleGroupMembership.rejected, (state, action) => {
        state.waiting = false;
      })
  },
});

export const { resetAPIKeyObject } = authSlice.actions;
export default authSlice.reducer;
