import { useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import {
  ArrowLeftStartOnRectangleIcon,
  BellIcon,
  Cog8ToothIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  UserGroupIcon,
  UserIcon,
  UsersIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import ClickableLink from "../layout/ClickableLink";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { generateTranslatedText } from "../../utils/boilerplate";
import { logout } from "../../slices/auth";
import { addAlert } from "../../slices/alert";
import Modal from "../layout/Modal";
import SearchInputWithIcon from "../forms/SearchInputWithIcon";
import { useSearchParams } from "react-router-dom";
import Button from "../layout/Button";
import { Bars3Icon } from "@heroicons/react/24/solid";

export default function Navbar({
  showSearch = false
}: {
  showSearch?: boolean
}) {
  const dispatch = useAppDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const { user, isAuthenticated, loading } = useAppSelector((state) => state.auth);
  const language = "EN";
  const [menuOpen, setMenuOpen] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [expandSearchBar, setExpandSearchBar] = useState(false);

  // Functions
  async function triggerLogout(e: any) {
    e.preventDefault();
    const res: any = await dispatch(logout());
    if (res.payload.status === 205) {
      setMenuOpen(false);
      dispatch(
        addAlert(generateTranslatedText("logout_success", language), "success", 5000),
      );
    } else {
      dispatch(addAlert(generateTranslatedText("logout_error", language), "danger"));
    }
  }

  const navigationLinks = [
    { name: "Home", href: "/", icon: HomeIcon, onClick: null, showAlert: 0, authenticated: false },
    { name: "Notifications", href: "/notifications", icon: BellIcon, onClick: null, showAlert: user === null ? 0 : user.profile.num_notifications, authenticated: false },
    { name: "Profile", href: "/profile", icon: UserIcon, onClick: null, showAlert: 0, authenticated: true },
    { name: "Groups", href: "/groups", icon: UserGroupIcon, onClick: null, showAlert: 0, authenticated: false },
    { name: "Find People", href: "/profiles", icon: UsersIcon, onClick: null, showAlert: 0, authenticated: false },
    { name: "Settings", href: "/settings", icon: Cog8ToothIcon, onClick: null, showAlert: 0, authenticated: true },
    { name: "Logout", href: null, icon: ArrowLeftStartOnRectangleIcon, onClick: triggerLogout, showAlert: 0, authenticated: true },
  ]

  const filteredNavigationLinks = navigationLinks.filter((link) => {
    if (link.authenticated) {
      return isAuthenticated;
    }
    return true;
  })

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  }

  function handleSubmit(e: any) {
    let updatedParams = searchParams;
    updatedParams.set("query", searchValue);
    setSearchParams(updatedParams);
  }

  return (
    <header className="bg-white dark:bg-black-light text-black dark:text-white border-b-2 border-black dark:border-light sticky top-0 z-40">

      {/* Help modal */}
      <Modal
        show={showHelpModal}
        setShow={setShowHelpModal}
        confirmText=""
        cancelText=""
        confirmHandler={() => { }}
        showActionButtons={false}
        showTriggerButton={false}
      >
        <div className="overflow-auto px-1">
          <div className="text-center">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-black dark:text-white">
              {generateTranslatedText("help", language)}
            </Dialog.Title>
            <div className="my-10 grid grid-cols-1 gap-y-8">
              <p className="text-base text-black dark:text-white">If you have any questions or issues with the site please contact <a href="mailto:help@filmwaffle.com" target="_blank" rel="noreferrer" className="text-danger hover:underline">help@filmwaffle.com</a></p>
            </div>
          </div>
        </div>
      </Modal>

      {/* Desktop */}
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between gap-x-4 p-6 lg:px-8"
        aria-label="Global"
      >
        {/* Logo */}
        <div className={`${expandSearchBar ? "hidden sm:block" : "block"} flex lg:flex-1`}>
          <a href="/" className="group flex items-center gap-x-4 shrink-0">
            <span className="sr-only">FilmWaffle</span>
            <span className="hidden dark:block">
              <img
                className="bg-black hidden md:block h-12 w-auto rounded-full border-0 ring ring-2 ring-white group-hover:ring-danger ring-offset-2 ring-offset-black"
                src="/img/logo/logo_headshot_white_transparent_bg.png"
                alt="FilmWaffle logo"
              />
            </span>
            <span className="block dark:hidden">
              <img
                className="bg-light hidden md:block dark:hidden h-12 w-auto rounded-full border-0 ring ring-2 ring-black group-hover:ring-danger ring-offset-2 ring-offset-white"
                src="/img/logo/logo_headshot_black_transparent_bg.png"
                alt="FilmWaffle logo"
              />
            </span>
            <span className="text-black dark:text-white text-2xl md:text-4xl font-extrabold tracking-wider">FILM<span className="text-danger">WAFFLE</span></span>
          </a>
        </div>

        <div className={`flex items-center gap-x-4 ${expandSearchBar === true ? "w-full justify-between" : ""}`}>

          {/* Search for posts */}
          {showSearch && (
            <div className={`grid grid-cols-1 md:w-72 lg:w-96`}>
              {/* Small viewport */}
              <input
                id="navbar-search"
                type="text"
                placeholder={generateTranslatedText("search_topics", language)}
                className={`block sm:hidden col-start-1 row-start-1 block w-${expandSearchBar === true ? "full" : "12"} sm:w-full rounded-full ${expandSearchBar === true ? "bg-light dark:bg-black pr-10" : "bg-transparent"} py-1.5 pl-3 text-base ${expandSearchBar === true ? "text-black dark:text-white placeholder:text-light/50" : "text-transparent placeholder:text-transparent"} ${expandSearchBar === true ? "border-1 border-light/50" : "border-none"} focus:border-2 focus:border-danger ring-0 focus:ring-0 outline-0 sm:pr-9 sm:text-sm/6`}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              {expandSearchBar ? (
                <XMarkIcon
                  aria-hidden="true"
                  className={`block sm:hidden ${!loading && "cursor-pointer"} col-start-1 row-start-1 mr-4 sm:mr-3 size-5 self-center justify-self-end text-danger ${loading && "animate-pulse"}`}
                  onClick={(e) => {
                    setExpandSearchBar(false)
                  }
                  }
                />
              ) : (
                <MagnifyingGlassIcon
                  aria-hidden="true"
                  className={`block sm:hidden ${!loading && "cursor-pointer"} col-start-1 row-start-1 size-5 self-center justify-self-end text-black dark:text-white ${loading && "animate-pulse"}`}
                  onClick={(e) => setExpandSearchBar(true)}
                />
              )}

              {/* > Small viewport */}
              <input
                id="navbar-search"
                type="text"
                placeholder={generateTranslatedText("search_topics", language)}
                className={`hidden sm:block col-start-1 row-start-1 block w-full rounded-full bg-light dark:bg-black pr-10 py-1.5 pl-3 text-base text-black dark:text-white placeholder:text-light/50 border-1 border-light/50 focus:border-2 focus:border-danger ring-0 focus:ring-0 outline-0 sm:pr-9 sm:text-sm/6`}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <MagnifyingGlassIcon
                aria-hidden="true"
                className={`hidden sm:block ${!loading && "cursor-pointer"} col-start-1 row-start-1 mr-4 sm:mr-3 size-5 self-center justify-self-end text-danger ${loading && "animate-pulse"}`}
                onClick={(e) => setExpandSearchBar(true)}
              />
            </div>
          )}

          <div className={`flex items-center gap-x-4`}>
            {/* Help button */}
            <button className="flex" onClick={() => setShowHelpModal(true)}>
              <QuestionMarkCircleIcon className="h-6 w-6 text-black dark:text-white hover:text-danger" aria-hidden="true" />
            </button>

            {/* Auth buttons */}
            {loading ? null : !isAuthenticated || !user ? (
              <>
                <div className="hidden sm:block flex items-center justify-end">
                  <span className="flex space-x-4">
                    <ClickableLink
                      href="/login"
                      bgColour="danger hover:bg-danger-dark"
                      textColour="text-white"
                      borderColour="danger"
                      shadow="none"
                    >
                      {generateTranslatedText("login", language)}
                    </ClickableLink>
                    <ClickableLink
                      bgColour="black hover:bg-black-light"
                      textColour="text-white"
                      borderColour="black"
                      href="/register"
                    >
                      {generateTranslatedText("sign_up", language)}
                    </ClickableLink>
                  </span>
                </div>

                <div className="block sm:hidden flex items-center justify-end">
                  <Button
                    bgColour="transparent"
                    textColour="text-black dark:text-white"
                    borderColour="none"
                    onClick={() => setMenuOpen(true)}
                    paddingX="0"
                    paddingY="0"
                  >
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </Button>
                </div>

              </>
            ) : (
              <div className="flex items-center justify-end">
                <span className="hidden sm:block">
                  <button
                    type="button"
                    onClick={() => setMenuOpen(true)}
                  >
                    <img className="h-10 w-10 object-cover rounded-full border-none ring ring-2 ring-black dark:ring-white hover:ring-danger ring-offset-2 ring-offset-white dark:ring-offset-black" src={user.profile.profile_picture || "/img/favicon/favicon.svg"} alt={user.username} />
                  </button>
                </span>
                <span className="block sm:hidden">
                  <Button
                    bgColour="transparent"
                    textColour="text-black dark:text-white"
                    borderColour="none"
                    onClick={() => setMenuOpen(true)}
                    paddingX="0"
                    paddingY="0"
                  >
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </Button>
                </span>
              </div>
            )}
          </div>

        </div>



      </nav>

      {/* Menu */}
      <Dialog
        open={menuOpen}
        onClose={setMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white dark:bg-black border-l-2 border-black dark:border-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-secondary/30">
          <div className="flex items-center justify-between gap-x-6">

            {/* Logo */}
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">FilmWaffle</span>
              <img
                className="bg-black hidden dark:block h-12 w-auto rounded-full border-0 ring ring-2 ring-white group-hover:ring-danger ring-offset-2 ring-offset-black"
                src="/img/logo/logo_headshot_white_transparent_bg.png"
                alt="FilmWaffle logo"
              />
              <img
                className="bg-light block dark:hidden h-12 w-auto rounded-full border-0 ring ring-2 ring-black group-hover:ring-danger ring-offset-2 ring-offset-white"
                src="/img/logo/logo_headshot_black_transparent_bg.png"
                alt="FilmWaffle logo"
              />
            </a>

            {/* Close button */}
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-black dark:text-white"
              onClick={() => setMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {/* Navigation links */}
          <div className="grid grid-cols-1 mt-12 divide-y divide-light/50 dark:divide-light/20">

            {/* Links */}
            {filteredNavigationLinks.map((link) => (
              <a className="" key={link.name} href={link.href || "#!"} onClick={(e) => link.onClick === null ? null : link.onClick(e)}>
                <div className="flex items-center justify-between py-4 px-4 hover:bg-light dark:hover:bg-black-light rounded-md">
                  <div className="flex items-center">
                    <link.icon className={`h-6 w-6 text-danger ${link.showAlert && "animate-bounce"}`} aria-hidden="true" />
                    <span className="ml-3 text-base font-medium text-black dark:text-white">{link.name}</span>
                    {link.showAlert > 0 && (
                      <span className="ml-2 bg-danger text-white text-xs font-semibold rounded-full px-2 py-1">{link.showAlert}</span>
                    )}
                  </div>
                </div>
              </a>
            ))}

            {/* Auth buttons */}
            {loading ? null : !isAuthenticated || !user ? (
              <ClickableLink
                href="/login"
                bgColour="danger hover:bg-danger-dark"
                textColour="text-white"
                borderColour="danger"
                shadow="none"
                className="mt-4"
              >
                {generateTranslatedText("login", language)}
              </ClickableLink>
            ) : null}
            {loading ? null : !isAuthenticated || !user ? (
              <ClickableLink
                bgColour="black hover:bg-black-light"
                textColour="text-white"
                borderColour="black"
                href="/register"
                className="mt-4"
              >
                {generateTranslatedText("sign_up", language)}
              </ClickableLink>
            ) : null}

          </div>

        </DialogPanel>
      </Dialog>
    </header>
  );
}
