import { ChevronRightIcon } from "@heroicons/react/24/outline";

export interface BreadcrumbProps {
  href: string;
  text: string | undefined;
}

interface BreadcrumbsProps {
  breadcrumbs: Array<BreadcrumbProps>;
}

export default function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  const numBreadcrumbs = breadcrumbs.length;
  if (numBreadcrumbs === 0) {
    return null;
  } else if (numBreadcrumbs === 1) {
    return (
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div className="flex">
              <a href={breadcrumbs[0].href} className="text-sm font-medium text-black dark:text-secondary hover:text-danger">
                {breadcrumbs[0].text}
              </a>
            </div>
          </li>
        </ol>
      </nav>
    );
  } else if (numBreadcrumbs === 2) {
    return (
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-4">
          <li>
            <div className="flex">
              <a href={breadcrumbs[0].href} className="text-sm font-medium text-black dark:text-secondary hover:text-danger">
                {breadcrumbs[0].text}
              </a>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
              <a href={breadcrumbs[1].href} className="ml-4 text-sm font-medium text-black dark:text-secondary hover:text-danger">
                {breadcrumbs[1].text}
              </a>
            </div>
          </li>
        </ol>
      </nav>
    );
  }
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div className="flex">
            <a href={breadcrumbs[0].href} className="text-sm font-medium text-black dark:text-secondary hover:text-danger">
              {breadcrumbs[0].text}
            </a>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <a href="#!" className="ml-4 text-sm font-medium text-black dark:text-secondary hover:text-danger">
              ...
            </a>
          </div>
        </li>
        <li>
          <div className="flex items-center">
            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <a href={breadcrumbs[numBreadcrumbs - 1].href} className="ml-4 text-sm font-medium text-black dark:text-secondary hover:text-danger">
              {breadcrumbs[numBreadcrumbs - 1].text}
            </a>
          </div>
        </li>
      </ol>
    </nav>

  );
}