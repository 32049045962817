import { useAppDispatch, useAppSelector } from "../../hooks";
import SpaceFiller from "../layout/SpaceFiller";
import { useEffect, useState } from "react";
import {
  getAuth,
} from "../../slices/auth";
import Template from "../app/Template";
import LoadingBubbles from "../layout/LoadingBubbles";
import { generateTranslatedText } from "../../utils/boilerplate";
import { BookmarkIcon, ChatBubbleOvalLeftIcon, PencilIcon, PencilSquareIcon, StarIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import Tabs from "../layout/Tabs";
import { getPostsByProfile, PostState } from "../../slices/content";
import FeedFilmItem from "../layout/FeedFilmItem";
import Button from "../layout/Button";
import { getGroupsByQuery, GroupState } from "../../slices/interest";
import Card from "../layout/Card";


export default function Profile() {
  const dispatch = useAppDispatch();

  // App level state from redux store
  const {
    user,
    loading,
  } = useAppSelector((state) => state.auth);
  const {
    posts: {
      data: postsData,
      loading: postsLoading,
      waiting: postsWaiting,
      pagination: postsPagination
    }
  } = useAppSelector((state) => state.content);
  const {
    groups: {
      data: groupsData,
      loading: groupsLoading,
      pagination: groupsPagination
    }
  } = useAppSelector((state) => state.interest);

  useEffect(() => {
    dispatch(getAuth());
  }, [dispatch]);

  useEffect(() => {
    // Fetch posts only when user.profile.id is defined
    if (user?.profile?.id) {
      dispatch(getPostsByProfile({ pageNumber: 1, profileId: user.profile.id }));
    }
  }, [dispatch, user?.profile?.id]);

  // Component level state
  const [page, setPage] = useState(1);

  // Functions
  async function clickOnTab(e: any, args: any[]) {
    e.preventDefault();
    if (user === null) {
      return;
    }
    const tabId = args[0];
    if (tabId === "posts") {
      dispatch(getPostsByProfile({ pageNumber: page, profileId: user?.profile.id }));
    } else if (tabId === "saved") {
      console.log("Saved");
    } else if (tabId === "groups") {
      dispatch(getGroupsByQuery({ pageNumber: page, query: null, groupId: null, profileId: user?.profile.id }));
    }
  }

  // Constant variables
  const language = "EN";
  return (
    <Template>

      <div className="grid grid-cols-1 gap-4 mt-16">

        {/* Loading spinner */}
        {loading || user === null ? (
          <SpaceFiller height="96">
            <div className="flex justify-center">
              <LoadingBubbles />
            </div>
          </SpaceFiller>
        ) : (
          <>

            {/* Photos */}
            <div className="w-full">

              {/* Cover photo */}
              <img
                src={user.profile.cover_picture === null ? "/img/graphics/placeholder_cover_picture_white.svg" : user.profile.cover_picture}
                alt={user.username}
                className="bg-white block dark:hidden w-full h-64 object-contain rounded-lg z-10"
              />
              <img
                src={user.profile.cover_picture === null ? "/img/graphics/placeholder_cover_picture_black.svg" : user.profile.cover_picture}
                alt={user.username}
                className="bg-black-light hidden dark:block w-full h-64 object-contain rounded-lg z-10"
              />

              {/* Profile photo */}
              <div className="p-1 rounded-full w-36 h-36 absolute left-1/2 transform -translate-x-1/2 z-20">
                <img
                  src={user.profile.profile_picture === null ? "/img/favicon/favicon.svg" : user.profile.profile_picture}
                  alt="Profile"
                  className="w-32 h-32 object-cover rounded-full -mt-16 border-4 border-light dark:border-black-light z-20"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-y-12 mt-8">
              {/* Metadata */}
              <div className="flex flex-col items-center gap-y-2 mt-16">

                {/* Username */}
                <span className="flex gap-4 flex-row items-center">
                  <h1 className="text-xl font-bold text-black dark:text-white">
                    {user.username}
                  </h1>

                  <a href="/profile/edit" className="group flex gap-x-2 items-center text-sm text-black dark:text-white hover:text-danger rounded-md border border-black dark:border-white hover:border-danger px-2 py-1 cursor-pointer">
                    <PencilIcon className="w-4 h-4 text-black dark:text-white group-hover:text-danger" />
                    {generateTranslatedText("edit_profile", language)}
                  </a>
                </span>

                {/* Tagline */}
                {user.profile.tagline !== "" && (
                  <p className="text-base text-black dark:text-white text-center">
                    {user.profile.tagline}
                  </p>
                )}

                {/* Joined on */}
                <p className="text-sm text-black dark:text-secondary">
                  {`${generateTranslatedText("joined_on", language)} ${user.profile.created_date}`}
                </p>

                <div className="flex flex-row gap-4">

                  {/* Number of posts */}
                  <span className="flex items-center justify-center gap-x-1 text-black dark:text-secondary text-sm">
                    <PencilSquareIcon className="w-5 h-5 text-danger" />
                    {`${user.profile.num_posts} Posts`}
                  </span>

                  {/* Number of comments */}
                  <span className="flex items-center justify-center gap-x-1 text-black dark:text-secondary text-sm">
                    <ChatBubbleOvalLeftIcon className="w-5 h-5 text-danger" />
                    {`${user.profile.num_comments} Comments`}
                  </span>

                  {/* Number of upvotes */}
                  <span className="flex items-center justify-center gap-x-1 text-black dark:text-secondary text-sm">
                    <StarIcon className="w-5 h-5 text-danger" />
                    {`${user.profile.num_upvotes}`}
                  </span>
                </div>

              </div>

              {/* Content */}
              <Tabs
                tabHeaders={
                  [
                    { id: "posts", name: generateTranslatedText("posts", language), icon: PencilSquareIcon, count: user.profile.num_posts, showCount: false },
                    { id: "saved", name: generateTranslatedText("saved", language), icon: BookmarkIcon, count: 0, showCount: false },
                    { id: "groups", name: generateTranslatedText("groups", language), icon: UserGroupIcon, count: 0, showCount: false },
                  ]
                }
                tabContents={[
                  {
                    id: "posts",
                    content: (
                      <>
                        {postsLoading ? (
                          <div className="w-full flex justify-center my-16">
                            <LoadingBubbles />
                          </div>
                        ) : (
                          <>
                            {postsData.map((post: PostState) => (
                              <FeedFilmItem post={post} key={post.id} />
                            ))}

                            {/* Load more content */}
                            {postsPagination.has_next && (
                              <div className="flex justify-center items-center my-10">
                                {postsWaiting ? (
                                  <div className="flex justify-center items-center w-full my-20">
                                    <LoadingBubbles />
                                  </div>
                                ) : (
                                  <Button
                                    bgColour="danger/20 hover:bg-danger/40"
                                    textColour="black"
                                    className="items-center w-fit"
                                    onClick={() => {
                                      dispatch(getPostsByProfile({ pageNumber: page + 1, profileId: user?.profile.id }))
                                      setPage(page + 1);
                                    }}
                                  >
                                    <span className="flex items-center gap-x-1 text-black dark:text-white">
                                      {generateTranslatedText("load_more_content", language)}
                                    </span>
                                  </Button>
                                )}
                              </div>
                            )}
                          </>
                        )
                        }
                      </>
                    )
                  },
                  {
                    id: "groups",
                    content: (
                      <>
                        {groupsLoading ? (
                          <div className="w-full flex justify-center my-16">
                            <LoadingBubbles />
                          </div>
                        ) : (
                          <>
                            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-4">
                              {groupsData.map((group: GroupState) => (
                                <a href={`/group/${group.slug}`} key={group.slug} className="group">
                                  <Card className="group-hover:border-light/20">
                                    <div className="grid grid-cols-1 justify-center text-center gap-y-4 py-4">

                                      {/* Profile picture */}
                                      <div className="w-full flex justify-center">
                                        <img
                                          src={group.profile_picture === null ? "/img/graphics/placeholder_group_profile_picture.svg" : group.profile_picture}
                                          alt={group.name}
                                          className="w-32 h-32 object-cover rounded-full group-hover:ring-4 group-hover:ring-light/50"
                                        />
                                      </div>

                                      {/* Name */}
                                      <h1 className="text-xl font-bold text-white">
                                        {group.name}
                                      </h1>

                                      {/* Created at */}
                                      <p className="text-sm text-secondary">
                                        {`${generateTranslatedText("created_on", language)} ${group.created_date}`}
                                      </p>

                                      <div className="flex justify-center gap-4">

                                        {/* Number of members */}
                                        <span className="flex items-center justify-center gap-x-1 text-secondary text-sm">
                                          <UserGroupIcon className="w-5 h-5 text-warning" />
                                          {`${group.num_members} ${group.num_members === 1 ? "Member" : "Members"}`}
                                        </span>
                                      </div>

                                    </div>
                                  </Card>
                                </a>
                              ))}
                            </div>

                            {/* Load more content */}
                            {groupsPagination.has_next && (
                              <div className="flex justify-center items-center my-10">
                                {postsWaiting ? (
                                  <div className="flex justify-center items-center w-full my-20">
                                    <LoadingBubbles />
                                  </div>
                                ) : (
                                  <Button
                                    bgColour="danger/20 hover:bg-danger/40"
                                    textColour="black"
                                    className="items-center w-fit"
                                    onClick={() => {
                                      dispatch(getGroupsByQuery({ pageNumber: page + 1, query: null, groupId: null, profileId: user?.profile.id }))
                                      setPage(page + 1);
                                    }}
                                  >
                                    <span className="flex items-center gap-x-1 text-black dark:text-white">
                                      {generateTranslatedText("load_more_content", language)}
                                    </span>
                                  </Button>
                                )}
                              </div>
                            )}
                          </>
                        )
                        }
                      </>
                    )
                  },
                ]}
                onClickTab={clickOnTab}
              />
            </div>

          </>
        )}

      </div>
    </Template>
  );
}
