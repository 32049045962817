export const lookup: any = {
  sign_in: {
    EN: "Sign in",
  },
  sign_in_title: {
    EN: "Sign in to your account",
  },
  sign_up: {
    EN: "Sign Up",
  },
  sign_up_title: {
    EN: "Create an account!",
  },
  email: {
    EN: "Email address",
  },
  password: {
    EN: "Password",
  },
  password_placeholder: {
    EN: "Enter your password...",
  },
  choose_password_placeholder: {
    EN: "Choose a password...",
  },
  confirm_password: {
    EN: "Confirm password",
  },
  confirm_password_placeholder: {
    EN: "Confirm your password...",
  },
  forgot_your_password: {
    EN: "Forgot your password?",
  },
  first_name: {
    EN: "First name",
  },
  first_name_placeholder: {
    EN: "What is your first name?",
  },
  last_name: {
    EN: "Last name",
  },
  last_name_placeholder: {
    EN: "What is your last name?",
  },
  email_placeholder: {
    EN: "What is your email?",
  },
  language: {
    EN: "Language",
  },
  language_placeholder: {
    EN: "What's your preferred language?",
  },
  age_confirmation: {
    EN: "Age confirmation",
  },
  age_confirmation_detail: {
    EN: "Please confirm that you are over the age of 18",
  },
  age_confirmation_final_detail: {
    EN: "By ticking this box, you are confiming that you are over the age of 18 and are legally allowed to use this service.",
  },
  agree_tos: {
    EN: "Agreed to Terms of Service",
  },
  agree_tos_detail: {
    EN: "Do you agree to our policies?",
  },
  agree_tos_final_detail: {
    EN: "By ticking this box, you are confiming that you have read fully, understood and agree to our ",
  },
  tos: {
    EN: "Terms of Service",
  },
  user_agreement: {
    EN: "User Agreement",
  },
  privacy_policy: {
    EN: "Privacy Policy",
  },
  cookie_policy: {
    EN: "Cookie Policy",
  },
  forgotten_password_title: {
    EN: "Forgotten your password?",
  },
  forgotton_password_subtext: {
    EN: "Enter your email address below and we will send you a link to reset your password.",
  },
  submit: {
    EN: "Submit",
  },
  verification_title: {
    EN: "We need to verify your account",
  },
  verification_subtext: {
    EN: "You should have received an email with a verification link. Please click the link to verify your account.",
  },
  resend_email: {
    EN: "Resend email",
  },
  send: {
    EN: "Send",
  },
  email_verification_pre_text: {
    EN: "Can't find the email?",
  },
  email_verification_resend: {
    EN: "Enter your email below and click send, we'll send you another.",
  },
  email_resend_placeholder: {
    EN: "Enter your email address...",
  },
  verification_confirm_title_fail: {
    EN: "We're unable to verify your account",
  },
  verification_confirm_subtext_fail: {
    EN: "Please click on your verification link again, or click the button below to return to the verification page where you can re-send another email.",
  },
  return_to_verification: {
    EN: "Return to verification",
  },
  verification_confirm_title_success: {
    EN: "Your account has been verified!",
  },
  verification_confirm_subtext_success: {
    EN: "Thank you for verifying your account, you're free to login to your account!",
  },
  login: {
    EN: "Login",
  },
  link_expired: {
    EN: "Link expired!",
  },
  link_expired_subtext: {
    EN: "Generate a new recovery link by clicking on the button below",
  },
  submit_email_again: {
    EN: "Submit my email again",
  },
  reset_password: {
    EN: "Reset password",
  },
  reset_password_subtext: {
    EN: "Enter a new password below and submit",
  },
  new_password: {
    EN: "New password",
  },
  new_password_placeholder: {
    EN: "Enter your new password...",
  },
  settings: {
    EN: "Settings",
  },
  search: {
    EN: "Search",
  },
  welcome: {
    EN: "Welcome",
  },
  no_data: {
    EN: "No data",
  },
  no_data_subtext: {
    EN: "There is no data to display",
  },
  login_success: {
    EN: "Login successful, welcome back!",
  },
  login_error: {
    EN: "Login error, please check your credentials and try again.",
  },
  go_to_app: {
    EN: "Find me food!",
  },
  logout: {
    EN: "Logout",
  },
  logout_success: {
    EN: "Logout successful, see you soon!",
  },
  logout_error: {
    EN: "Logout error, please try again.",
  },
  search_string_placeholder: {
    EN: "Search for a restaurant...",
  },
  search_members: {
    EN: "Search for members...",
  },
  search_topics: {
    EN: "Search for topics...",
  },
  create_post: {
    EN: "Create Post",
  },
  ask_a_question: {
    EN: "Ask a question",
  },
  create_group: {
    EN: "Create Group",
  },
  top_groups: {
    EN: "Top Groups",
  },
  top: {
    EN: "Top",
  },
  trending: {
    EN: "Trending",
  },
  new: {
    EN: "New",
  },
  for_you: {
    EN: "For you",
  },
  load_more_content: {
    EN: "Load More...",
  },
  share_to_social: {
    EN: "Share to your socials!",
  },
  close: {
    EN: "Close",
  },
  copied_to_clipboard: {
    EN: "Copied to clipboard!",
  },
  share_text_reddit: {
    EN: "Check out this post on FilmWaffle!"
  },
  vote_error: {
    EN: "I came across an issue casting your vote, please try again.",
  },
  if_you_want_to_vote: {
    EN: "If you want to vote,",
  },
  please_log_in: {
    EN: "Please log in!",
  },
  reply: {
    EN: "Reply",
  },
  reply_placeholder: {
    EN: "Add a reply...",
  },
  if_you_want_to_comment: {
    EN: "If you want to comment,",
  },
  please_log_in_comment: {
    EN: "Please log in!",
  },
  comment_success: {
    EN: "Comment added successfully!",
  },
  comment_error: {
    EN: "I had an issue adding your comment, please try again.",
  },
  create_post_placeholder: {
    EN: "Write your post here!",
  },
  create_question_placeholder: {
    EN: "Ask your question here!",
  },
  title: {
    EN: "Title",
  },
  create_a_post: {
    EN: "Create a post",
  },
  upload_image_dropzone: {
    EN: "Click to add an image or drag and drop",
  },
  max_photos_reached_post: {
    EN: "You can only choose 1 image per post!",
  },
  process_photo_error: {
    EN: "I had an issue processing your image, please try again.",
  },
  create: {
    EN: "Create",
  },
  missing_title_post: {
    EN: "Please add a title to your post!",
  },
  missing_body_post: {
    EN: "Please add some content to your post!",
  },
  post_created: {
    EN: "Your post has been added!",
  },
  post_created_error: {
    EN: "I had an issue adding your post, please try again.",
  },
  if_you_want_to_post: {
    EN: "If you want to post,",
  },
  edit: {
    EN: "Edit",
  },
  edit_post: {
    EN: "Edit Post",
  },
  edit_post_placeholder: {
    EN: "Edit your post...",
  },
  if_you_want_to_edit_post: {
    EN: "If you want to edit your post,",
  },
  post_updated: {
    EN: "Your post has been updated!",
  },
  post_updated_error: {
    EN: "I had an issue updating your post, please try again.",
  },
  delete: {
    EN: "Delete",
  },
  delete_post_confirmation: {
    EN: "Are you sure you want to delete this post?",
  },
  if_you_want_to_delete_post: {
    EN: "If you want to delete your post,",
  },
  missing_id_post: {
    EN: "I had an issue finding your post, please try again.",
  },
  post_deleted: {
    EN: "Your post has been deleted!",
  },
  post_deleted_error: {
    EN: "I had an issue deleting your post, please try again.",
  },
  delete_post: {
    EN: "Delete Post",
  },
  if_you_want_to_edit_comment: {
    EN: "If you want to edit your comment,",
  },
  if_you_want_to_delete_comment: {
    EN: "If you want to delete your comment,",
  },
  edit_comment: {
    EN: "Edit Comment",
  },
  edit_comment_placeholder: {
    EN: "Edit your comment...",
  },
  delete_comment: {
    EN: "Delete Comment",
  },
  delete_comment_confirmation: {
    EN: "Are you sure you want to delete this comment?",
  },
  missing_body_comment: {
    EN: "Please add some content to your comment!",
  },
  comment_updated: {
    EN: "Your comment has been updated!",
  },
  comment_updated_error: {
    EN: "I had an issue updating your comment, please try again.",
  },
  missing_id_comment: {
    EN: "I had an issue finding your comment, please try again.",
  },
  comment_deleted: {
    EN: "Your comment has been deleted!",
  },
  comment_deleted_error: {
    EN: "I had an issue deleting your comment, please try again.",
  },
  joined_on: {
    EN: "Joined on",
  },
  posts: {
    EN: "Posts",
  },
  saved: {
    EN: "Saved",
  },
  groups: {
    EN: "Groups",
  },
  edit_profile: {
    EN: "Edit Profile",
  },
  username: {
    EN: "Username",
  },
  username_placeholder: {
    EN: "Choose a username...",
  }
  ,
  bio: {
    EN: "Bio",
  },
  bio_placeholder: {
    EN: "Tell us your favourite movies, genres, actors and let the community know your love for cinema!",
  },
  profile_picture: {
    EN: "Profile Picture",
  },
  cover_picture: {
    EN: "Cover Picture",
  },
  current_password: {
    EN: "Current password",
  },
  current_password_placeholder: {
    EN: "Enter your current password...",
  },
  profile_updated: {
    EN: "Your profile has been updated!",
  },
  profile_updated_error: {
    EN: "I had an issue updating your profile, please try again.",
  },
  update_passwords_do_not_match: {
    EN: "Your updated passwords do not match!",
  },
  current_password_missing: {
    EN: "Please add your current password to update it!",
  },
  search_modal_title: {
    EN: "Search for Posts",
  },
  dismiss: {
    EN: "Dismiss",
  },
  view: {
    EN: "View",
  },
  notification_dismissed: {
    EN: "Notification dismissed!",
  },
  notification_dismiss_error: {
    EN: "I had an issue dismissing your notification, please try again.",
  },
  no_notifications: {
    EN: "No notifications",
  },
  no_notifications_subtext: {
    EN: "You're all caught up! No new notifications to show.",
  },
  sign_up_with_google: {
    EN: "Sign up with Google",
  },
  sign_in_with_google: {
    EN: "Sign in with Google",
  },
  or: {
    EN: "or",
  },
  if_you_want_to_create_group: {
    EN: "If you want to create a group,",
  },
  create_a_group: {
    EN: "Create a group",
  },
  name: {
    EN: "Name",
  },
  description: {
    EN: "Description",
  },
  category: {
    EN: "Category",
  },
  group_created: {
    EN: "Your group has been created!",
  },
  group_created_error: {
    EN: "I had an issue creating your group, please try again.",
  },
  missing_name_group: {
    EN: "Please add a name to your group!",
  },
  missing_description_group: {
    EN: "Please add a description to your group!",
  },
  edit_group: {
    EN: "Edit Group",
  },
  created_at: {
    EN: "Created at",
  },
  no_group_posts: {
    EN: "No posts",
  },
  no_group_posts_subtext: {
    EN: "This group has no posts yet, be the first to post!",
  },
  if_you_want_to_update_profile: {
    EN: "If you want to update your profile,",
  },
  if_you_want_to_update_group: {
    EN: "If you want to update this group,",
  },
  group_not_found: {
    EN: "I had an issue finding your group, please try again.",
  },
  group_updated: {
    EN: "This group has been updated!",
  },
  group_updated_error: {
    EN: "I had an issue updating this group, please try again.",
  },
  username_login_placeholder: {
    EN: "Enter your username...",
  },
  password_login_placeholder: {
    EN: "Enter your password...",
  },
  register_username_taken_error: {
    EN: "This username is already taken!",
  },
  update_username_taken_error: {
    EN: "This username is already taken! Choose another one.",
  },
  last_updated_at: {
    EN: "edited at ",
  },
  delete_account_permanently: {
    EN: "Your account has been deleted, we're sorry to see you go!",
  },
  delete_account_permanently_error: {
    EN: "I had an issue deleting your account, please try again.",
  },
  delete_account: {
    EN: "Delete Account",
  },
  delete_account_confirmation: {
    EN: "Are you sure you want to delete your account? All your data will be deleted and you won't be able to create a new account with the email address associated with this account.",
  },
  danger_zone: {
    EN: "Danger Zone",
  },
  created_on: {
    EN: "Created on",
  },
  search_groups: {
    EN: "Search groups...",
  },
  leave_group: {
    EN: "Leave group",
  },
  join_group: {
    EN: "Join group",
  },
  login_to_join_group: {
    EN: "Please log in to join this group!",
  },
  profiles: {
    EN: "Profiles",
  },
  search_profiles: {
    EN: "Search for profiles...",
  },
  verification_email_sent: {
    EN: "Verification email re-sent!",
  },
  verification_email_sent_error: {
    EN: "I had an issue re-sending your verification email, please try again.",
  },
  email_required: {
    EN: "Please add your email address!",
  },
  invalid_email: {
    EN: "Please add a valid email address!",
  },
  password_recovery_email_sent: {
    EN: "Password recovery email sent! Check your email inbox.",
  },
  password_recovery_email_error: {
    EN: "I had an issue sending your password recovery email, please try again.",
  },
  register_success: {
    EN: "Registration successful, please check your email for a verification link!",
  },
  register_error: {
    EN: "Registration error, please try again.",
  },
  password_recovery_success: {
    EN: "Password reset successful, please log in!",
  },
  password_recovery_error: {
    EN: "I had an issue resetting your password, please try again.",
  },
  read_more: {
    EN: "Read more",
  },
  if_you_want_to_update_password: {
    EN: "If you want to update your password,",
  },
  password_updated: {
    EN: "Your password has been updated!",
  },
  update_password_current_wrong_error: {
    EN: "Your current password is incorrect!",
  },
  update_password_error: {
    EN: "I had an issue updating your password, please try again.",
  },
  max_image_file_size_error: {
    EN: "This image is too large! Please choose an image under 250KB.",
  },
  help: {
    EN: "Help",
  },
  tagline: {
    EN: "Tagline",
  },
  tagline_placeholder: {
    EN: "What is your tagline?",
  },
  grid: {
    EN: "Grid",
  },
  switch_to_discussion: {
    EN: "Switch to discussion",
  },
};

const defaultError: any = {
  EN: "Error!",
  FR: "Veuillez !",
  IT: "Errore!",
};

export function generateTranslatedText(action: string, language: string) {
  if (action in lookup) {
    const text = lookup[action][language];
    return text;
  }
  return defaultError[language];
}
