import { useState } from "react";

export default function FallingStar({
  height = "25px",
  width = "25px",
  colour = "black",
  hoverColour = "danger",
  parentId = "",
  filled = false,
  className = ""
}: {
  height?: string;
  width?: string;
  colour?: string;
  hoverColour?: string;
  parentId?: string;
  filled?: boolean;
  className?: string;
}) {

  const [hover, setHovered] = useState(false);

  // Get parent element by ID
  const parentElement = document.getElementById(parentId);

  // If parent element exists, add event listener
  if (parentElement) {
    parentElement.addEventListener("mouseover", () => setHovered(true));
    parentElement.addEventListener("mouseleave", () => setHovered(false));
  }

  // Constant variables
  const fillString = filled ? "solid" : "outline";
  const fileName = `/img/icons/falling-star-${hover ? hoverColour : colour}-${fillString}.svg`;

  // Default fill colour = white
  return (
    <img
      src={fileName}
      alt="Falling star"
      height={height}
      width={width}
      className={`flex shrink-0 ${className}`}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    />
  );
}
