import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import { getAuth, login } from "../../slices/auth";
import { LockOpenIcon } from "@heroicons/react/24/solid";
import { generateTranslatedText } from "../../utils/boilerplate";
import Button from "../layout/Button";
import Card from "../layout/Card";
import TextInput from "../forms/TextInput";
import GoogleLoginButton from "./GoogleLoginButton";
import Spinner from "../layout/Spinner";

export default function LoginForm() {
  const dispatch = useAppDispatch();
  const language = "EN";

  // App level state from redux store
  const { loading } = useAppSelector((state) => state.auth);

  const [loginState, setLoginState] = useState({
    username: "",
    password: "",
  });

  async function loginWithCredentials(e: any) {
    e.preventDefault();
    const { username, password } = loginState;
    const res: any = await dispatch(login({ username, password }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("login_success", language), "success"));
      dispatch(getAuth());
    } else {
      dispatch(
        addAlert(generateTranslatedText("login_error", language), "danger")
      );
    }
  }

  return (
    <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <Card
        paddingX={8}
        paddingY={8}
        className=" w-full max-w-md space-y-8"
      >
        <div>
          <a href="/">
            <img
              className="hidden dark:block mx-auto h-28 w-auto rounded-lg"
              src="/img/logo/logo_white_transparent_bg.svg"
              alt="FilmWaffle logo"
            />
            <img
              className="block dark:hidden mx-auto h-28 w-auto rounded-lg"
              src="/img/logo/logo_black_transparent_bg.svg"
              alt="FilmWaffle logo"
            />
          </a>

          {/* Google OAuth */}
          <div className="my-4 flex items-center justify-center">
            <GoogleLoginButton type="sign_in" />
          </div>

          <h2 className="text-center text-lg font-bold tracking-tight text-black dark:text-white">
            {`${generateTranslatedText("or", language)}...`}
          </h2>
          <h2 className="mt-2 text-center text-xl font-bold tracking-tight text-black dark:text-white">
            {generateTranslatedText("sign_in_title", "EN")}
          </h2>
        </div>
        <form
          className="mt-8 space-y-6"
          onSubmit={(e) => loginWithCredentials(e)}
        >
          <div className="grid grid-cols-1 gap-y-4 -space-y-px rounded-md">

            {/* Username */}
            <TextInput
              id="username"
              type="text"
              label={generateTranslatedText("username", "EN")}
              placeholder={generateTranslatedText("username_login_placeholder", "EN")}
              autoComplete="username"
              required={true}
              onChange={(e) => setLoginState({ ...loginState, username: e.target.value })}
              showLabel={true}
              value={loginState.username}
            />

            {/* Password */}
            <TextInput
              id="password"
              type="password"
              label={generateTranslatedText("password", "EN")}
              placeholder={generateTranslatedText("password_login_placeholder", "EN")}
              autoComplete="current-password"
              required={true}
              onChange={(e) => setLoginState({ ...loginState, password: e.target.value })}
              showLabel={true}
              value={loginState.password}
            />
          </div>

          <div className="flex items-center justify-between">
            <div className="text-sm">
              <a
                href="/forgot-my-password"
                className="font-medium text-secondary dark:text-light hover:text-danger"
              >
                {generateTranslatedText("forgot_your_password", "EN")}
              </a>
            </div>
          </div>

          <div>
            <Button
              type="submit"
              bgColour="danger hover:bg-danger-dark"
              disabled={loading}
              textColour="text-white"
            >
              <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                {loading ? <Spinner colour="black" size={4} /> : (
                  <LockOpenIcon
                    className="h-5 w-5 fill-white text-white"
                    aria-hidden="true"
                  />
                )}
              </span>
              {generateTranslatedText("sign_in", "EN")}
            </Button>
          </div>
        </form>
      </Card>
    </div>
  );
}
