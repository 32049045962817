import { ThreeDot } from "react-loading-indicators";

interface SpinnerProps {
  size?: "medium" | "large" | "small";
  colour?: string;
}

export default function LoadingBubbles({
  colour = "danger",
  size = "medium"
}: SpinnerProps) {

  let colourString = "#FFFFFF"
  if (colour === "warning") {
    colourString = "#FEA500"
  } else if (colour === "black") {
    colourString = "#000000"
  } else if (colour === "danger") {
    colourString = "#d80a00"
  }


  return (
    <ThreeDot variant="pulsate" color={colourString} size={size} text="" textColor="" />
  );
}