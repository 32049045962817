interface CheckboxesProps {
  id: string;
  label?: string;
  showLabel?: boolean;
  textColour?: string;
  options: Array<any[]>;
  state?: any;
  stateName?: any;
  onChange?: any;
  disabled?: boolean;
  baseValue: any;
}

export default function Checkboxes({
  id,
  label,
  showLabel = false,
  textColour = "secondary",
  options = [],
  state = {},
  stateName = "",
  onChange = () => { },
  disabled = false,
  baseValue,
}: CheckboxesProps) {
  function checkIfChecked(option: any) {
    if (state[stateName] === null) return option === baseValue;
    return option === state[stateName];
  }

  function createCheckbox(idx: number, option: any) {
    if (disabled) {
      return (
        <input
          id={`checkbox-${idx}-${id}`}
          className={`h-4 w-4 rounded border-2 border-black text-${textColour} focus:ring-danger`}
          name={stateName}
          value={option}
          type="checkbox"
          onChange={(e) =>
            onChange({
              ...state,
              [stateName]: state[stateName] === null ? true : !state[stateName],
            })
          }
          checked={checkIfChecked(option)}
          disabled
        />
      );
    }
    return (
      <input
        id={`checkbox-${idx}-${id}`}
        className={`h-4 w-4 rounded border-2 border-black text-${textColour} focus:ring-danger`}
        name={stateName}
        value={option}
        type="checkbox"
        onChange={(e) =>
          onChange({
            ...state,
            [stateName]: state[stateName] === null ? true : !state[stateName],
          })
        }
        checked={checkIfChecked(option)}
      />
    );
  }

  return (
    <div>
      {/* Label */}
      {showLabel ? (
        <label
          htmlFor={id}
          className={`mb-3 block text-sm font-medium text-${textColour} text-left`}
        >
          {label}
        </label>
      ) : (
        <label
          htmlFor={id}
          className={`mb-3 block text-sm font-medium text-${textColour} text-left`}
        />
      )}

      {/* Checkboxes */}
      <div className="mt-6 space-y-6">
        {options.map((option, index) => (
          <div className="relative flex gap-x-3" key={index}>
            <div className="flex h-6 items-center">
              {createCheckbox(index, option[0])}
            </div>
            <div className="text-sm leading-6">
              <label htmlFor="comments" className={`text-black dark:text-white`}>
                {option[1]}
              </label>
              {option[2] !== null && (
                <p className="text-xs text-secondary">
                  {option[2]}
                  {option[3].map((x: any, idx: number) => {
                    return (
                      <span key={x}>
                        <a className="underline hover:text-danger" href={x[1]} target="_blank" rel="noreferrer">
                          {x[0]}
                        </a>
                        {idx < option[3].length - 1 ? ", " : null}
                      </span>
                    );
                  })}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}