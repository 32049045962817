import { useEffect, useState } from "react";
import { AdState } from "../../slices/advertisement";

export default function BannerAds({ ads = [] }: { ads: AdState[] }) {

  // Component level state
  const [index, setIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true); // Trigger fade-out
      setTimeout(() => {
        setIndex((prev) => (prev === ads.length - 1 ? 0 : prev + 1));
        setIsFading(false); // Trigger fade-in
      }, 200); // Match the transition duration
    }, 25000);
    return () => clearInterval(interval);
  }, [ads]);

  if (ads.length === 0) return null;
  const currentAd = ads[index];
  return (
    <div className="relative overflow-hidden w-full h-32 sm:h-48 rounded-md mb-4">
      <a href={currentAd.url === null ? "#!" : currentAd.url} target="_blank" rel="noreferrer">

        {/* XS */}
        {currentAd.image_xs !== null && (
          <img
            src={currentAd.image_xs}
            alt={currentAd.title}
            className={`absolute block xs:hidden w-full h-full object-fill rounded-md transition-opacity duration-200 ease-in-out ${isFading ? "opacity-0" : "opacity-100"}`}
          />
        )}

        {/* SM */}
        {currentAd.image_sm !== null && (
          <img
            src={currentAd.image_sm}
            alt={currentAd.title}
            className={`absolute hidden xs:block w-full h-full object-fill rounded-md transition-opacity duration-200 ease-in-out ${isFading ? "opacity-0" : "opacity-100"}`}
          />
        )}

        {/* MD */}
        {currentAd.image_md !== null && (
          <img
            src={currentAd.image_md}
            alt={currentAd.title}
            className={`absolute hidden sm:block w-full h-full object-fill rounded-md transition-opacity duration-200 ease-in-out ${isFading ? "opacity-0" : "opacity-100"}`}
          />
        )}

        {/* LG */}
        {currentAd.image_lg !== null && (
          <img
            src={currentAd.image_lg}
            alt={currentAd.title}
            className={`absolute hidden lg:block w-full h-full object-fill rounded-md transition-opacity duration-200 ease-in-out ${isFading ? "opacity-0" : "opacity-100"}`}
          />
        )}

      </a>
    </div>
  );
}
