import Template from "../app/Template";
import TopGroups from "./TopGroups";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getVisualPosts, PostState } from "../../slices/content";
import { useSearchParams } from "react-router-dom";
import LoadingBubbles from "../layout/LoadingBubbles";
import Button from "../layout/Button";
import { generateTranslatedText } from "../../utils/boilerplate";
import MobileBottomToolbar from "./MobileBottomToolbar";
import BannerAds from "../advertisement/BannerAds";
import { useAds } from "../contexts/AdProvider";
import LandingFilters from "./LandingFilters";
import CreateOrSearchHeader from "./CreateOrSearchHeader";
import { ChatBubbleLeftRightIcon, Squares2X2Icon } from "@heroicons/react/24/solid";
import SwitchToggle from "../forms/SwitchToggle";

export default function VisualFeed() {
  const dispatch = useAppDispatch();

  // Get query params
  const [searchParams] = useSearchParams();
  const querySearch = searchParams.get("query");

  // Get app level contexts
  const { banner: { data: bannerAdsData, loading: bannerAdsLoading } } = useAds();

  // Get app level state from redux store
  const { loading: authLoading } = useAppSelector((state) => state.auth);
  const { posts: { data, loading, waiting, pagination } } = useAppSelector((state) => state.content);

  // On page load
  useEffect(() => {
    if (!authLoading) {
      dispatch(getVisualPosts({ pageNumber: 1, query: querySearch }));
    }
  }, [dispatch, querySearch, authLoading]);

  // Get component level state
  const [page, setPage] = useState(1);

  // Functions
  async function fetchNewPostsByPageAndQuery(e: any, args: any[]) {
    e.preventDefault();
    const [pageNumber, query] = args;
    dispatch(getVisualPosts({ pageNumber, query }));
  }

  const language = "EN";
  return (
    <Template showSearchInNavbar={true}>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 md:mt-16">

        {/* Posts content */}
        <div className="col-span-3">

          {/* Banner Ad */}
          {bannerAdsLoading ? (
            <div className="flex justify-center items-center w-full my-16">
              <LoadingBubbles />
            </div>
          ) : bannerAdsData.length === 0 ? null : (
            <BannerAds ads={bannerAdsData} />
          )}

          {/* Create post or search */}
          <CreateOrSearchHeader
            searchHandler={fetchNewPostsByPageAndQuery}
            searchHandlerArgs={[page]}
            loading={loading}
          />

          {/* Switch to discussion */}
          <div className="w-full flex justify-center items-center my-4">
            <div className="flex justify-center items-center gap-x-2">
              <p className="text-sm font-semibold text-black dark:text-white">Grid</p>
              <SwitchToggle
                id="feed-type"
                label=""
                showLabel={false}
                checked={!window.location.pathname.includes("/grid")}
                onChange={(e) => {
                  window.location.href = "/new";
                }}
                icon={window.location.pathname.includes("/grid") ? <Squares2X2Icon className="h-3 w-3 text-black" /> : <ChatBubbleLeftRightIcon className="h-3 w-3 text-black" />}
              />
              <p className="text-sm font-semibold text-black dark:text-white">Discussion</p>
            </div>
          </div>

          {/* Feed */}
          {loading ? (
            <div className="flex justify-center items-center w-full my-20">
              <LoadingBubbles />
            </div>
          ) : (
            <div className="grid grid-cols-2 xs:grid-cols-3 md:grid-cols-4 gap-2 mb-20">
              {data.map((post: PostState) => {
                return (
                  <a href={`/post/${post.slug}`} key={post.id} className="group">
                    <div className="relative w-full h-52 xs:h-64 md:h-80 group-hover:opacity-80 transition-opacity duration-300 rounded-md">
                      <img
                        src={post.image === null ? "#!" : post.image}
                        alt={post.title}
                        className="w-full h-full object-cover rounded-md"
                      />
                      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 rounded-b-md">
                        <p className="text-sm md:text-base lg:text-lg xl:text-lg font-bold">
                          {post.title}
                        </p>
                      </div>
                    </div>
                  </a>
                )
              })}
            </div>
          )}

          {/* Load more content */}
          {pagination.has_next && (
            <div className="flex justify-center items-center my-10">
              {waiting ? (
                <div className="flex justify-center items-center w-full my-20">
                  <LoadingBubbles />
                </div>
              ) : (
                <Button
                  bgColour="danger/20 hover:bg-danger/40"
                  textColour="black"
                  className="items-center w-fit"
                  onClick={() => {
                    dispatch(getVisualPosts({ pageNumber: page + 1, query: querySearch }))
                    setPage(page + 1);
                  }}
                >
                  <span className="flex items-center gap-x-1 text-black dark:text-white">
                    {generateTranslatedText("load_more_content", language)}
                  </span>
                </Button>
              )}
            </div>
          )}

        </div>

        {/* Groups content */}
        {/* <div className="col-span-1">
          <TopGroups />
        </div> */}

      </div>

      {/* Mobile toolbar */}
      <MobileBottomToolbar pageNumber={page} pageName="grid" loading={loading} />

    </Template>
  );
}
