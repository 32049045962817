import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "./Button";

interface ModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  confirmText: string;
  cancelText: string;
  confirmHandler: (e: any) => void;
  showActionButtons?: boolean;
  showTriggerButton?: boolean;
  children: React.ReactNode;
}

export default function Modal({
  show,
  setShow,
  confirmText,
  cancelText,
  confirmHandler,
  showActionButtons = true,
  showTriggerButton = true,
  children,
}: ModalProps) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        initialFocus={cancelButtonRef}
        onClose={setShow}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black-light bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-x-auto rounded-md bg-white dark:bg-black px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                {children}
                {showActionButtons && (
                  <div className={`mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 ${!showTriggerButton && "sm:grid-cols-1"} sm:gap-3`}>
                    {showTriggerButton && (
                      <Button
                        bgColour="danger hover:bg-danger-dark"
                        className="text-black sm:col-start-2"
                        onClick={(e) => confirmHandler(e)}
                        borderColour="danger"
                        textColour="text-white"
                      >
                        {confirmText}
                      </Button>
                    )}
                    <button
                      type="button"
                      className={`${!showTriggerButton && "col-span-full"} mt-3 inline-flex w-full justify-center rounded-md bg-transparent border-2 border-white hover:border-light px-3 py-2 text-sm font-semibold text-black dark:text-white sm:col-start-1 sm:mt-0`}
                      onClick={() => setShow(false)}
                      ref={cancelButtonRef}
                    >
                      {cancelText}
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}