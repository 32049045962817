import { Switch } from "@headlessui/react";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface SwitchToggleProps {
  id: string;
  label?: string;
  showLabel?: boolean;
  labelColour?: string;
  description?: string;
  descriptionSubtext?: string;
  checked: boolean;
  onChange?: (e: any) => void;
  icon?: React.ReactNode;
}

export default function SwitchToggle({
  id,
  label = "",
  showLabel = false,
  labelColour = "text-black",
  description = "",
  descriptionSubtext = "",
  checked,
  onChange,
  icon,
}: SwitchToggleProps) {

  return (
    <div className="flex items-center">
      {showLabel ? (
        <label
          htmlFor={id}
          className={`mb-3 block text-left text-sm font-medium ${labelColour}`}
        >
          {label}
        </label>
      ) : (
        <label
          htmlFor={id}
          className={`mb-3 block text-left text-sm font-medium ${labelColour}`}
        />
      )}
      <Switch.Group
        as="div"
        className="flex items-center text-left justify-between"
      >
        <span className="flex flex-grow flex-col">
          <Switch.Label
            as="span"
            className="text-xs italic font-normal leading-6 text-secondary"
            passive
          >
            {description}
          </Switch.Label>
          {descriptionSubtext !== "" && (
            <Switch.Description as="span" className="text-sm text-gray-500">
              {descriptionSubtext}
            </Switch.Description>
          )}
        </span>
        <Switch
          checked={checked}
          onChange={onChange}
          className={classNames(
            checked ? "bg-danger-dark" : "bg-danger-light",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-black transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-pink-light focus:ring-offset-2"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            className={classNames(
              checked ? "translate-x-5" : "translate-x-0",
              "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-2 ring-black transition duration-200 ease-in-out flex items-center justify-center"
            )}
          >
            {icon}
          </span>
        </Switch>
      </Switch.Group>
    </div>
  );
}