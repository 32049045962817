import Template from "../app/Template";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import LoadingBubbles from "../layout/LoadingBubbles";
import { dismissNotification, getNotifications, NotificationState } from "../../slices/notification";
import Card from "../layout/Card";
import Button from "../layout/Button";
import { generateTranslatedText } from "../../utils/boilerplate";
import { CheckBadgeIcon, EyeIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { addAlert } from "../../slices/alert";
import NoContent from "../layout/NoContent";

export default function Notifications() {
  const dispatch = useAppDispatch();

  // On page load
  useEffect(() => {
    dispatch(getNotifications({ pageNum: 1 }));
  }, [dispatch]);

  // Get app level state from redux store
  const { data, loading, waiting, pagination } = useAppSelector((state) => state.notification);

  // Get component level state
  const [page, setPage] = useState(1);

  // Functions
  async function updateNotification(id: string) {
    const res: any = await dispatch(dismissNotification({ notificationId: id }));
    if (res.payload.status === 200) {
      dispatch(addAlert(generateTranslatedText("notification_dismissed", language), "success"));
    } else {
      dispatch(addAlert(generateTranslatedText("notification_dismiss_error", language), "warning"));
    }
  }

  // Constant variables
  const language = "EN";
  return (
    <Template>

      <div className="grid grid-cols-1 gap-4 my-16">

        {/* Notifications */}
        <div>

          {/* Feed */}
          {loading ? (
            <div className="flex justify-center items-center w-full my-20">
              <LoadingBubbles />
            </div>
          ) : (
            <>
              {data.length === 0 ? (
                <NoContent
                  text={generateTranslatedText("no_notifications", language)}
                  subtext={generateTranslatedText("no_notifications_subtext", language)}
                  icon={<CheckBadgeIcon className="mx-auto h-12 w-12 text-danger animate-bounce" />}
                />
              ) : data.map((notif: NotificationState) => (
                <Card className="w-full grid grid-cols-1 gap-4 mb-4" key={notif.id}>

                  {/* Metadata */}
                  <span className="flex">
                    <div className="flex items-center gap-x-2">
                      <img src={notif.sender_pic !== null ? notif.sender_pic : "/img/favicon/favicon.svg"} alt={`Notification sent by ${notif.sender_ref}`} className="h-10 w-10 rounded-full" />
                      <div className="flex flex-col">
                        <div className="flex flex-row text-secondary overflow-hidden">
                          <span className="text-base font-normal text-black dark:text-white mr-1 truncate">{notif.sender_ref}</span>
                          <span className="truncate">
                            <a href={notif.url} target="_blank" rel="noreferrer" className="ml-1 text-base font-normal text-danger hover:underline cursor-pointer">{notif.category[1]}</a>
                          </span>
                        </div>
                        <span className="text-xs font-normal text-secondary">{notif.created_date}</span>
                      </div>
                    </div>
                  </span>

                  {/* Action buttons */}
                  <div className="w-full flex justify-start">
                    <div className="w-fit flex flex-row gap-4">

                      {/* Dismiss */}
                      <Button
                        bgColour="transparent"
                        borderColour="none"
                        className="items-center"
                        onClick={(e) => updateNotification(notif.id)}
                      >
                        <span className={`flex items-center gap-x-1 text-black dark:text-white group-hover:text-danger`}>
                          <XMarkIcon className="h-5 w-5 text-black dark:text-white group-hover:text-danger" />
                          {generateTranslatedText("dismiss", language)}
                        </span>
                      </Button>

                      {/* View */}
                      <Button
                        id={`view-${notif.id}`}
                        bgColour="transparent"
                        borderColour="none"
                        className="items-center"
                        onClick={(e) => {
                          // Open URL in new tab
                          window.open(notif.url, "_blank");
                        }}
                      >
                        <span className={`flex items-center gap-x-1 text-black dark:text-white group-hover:text-danger pr-1`}>
                          <EyeIcon className="h-5 w-5 text-black dark:text-white group-hover:text-danger" />
                          {generateTranslatedText("view", language)}
                        </span>
                      </Button>

                    </div>
                  </div>

                </Card>
              ))}
            </>
          )}

          {/* Load more */}
          {waiting && (
            <div className="flex justify-center items-center w-full my-20">
              <LoadingBubbles />
            </div>
          )}

          {/* Pagination */}
          {!pagination.has_next && (
            <div className="flex justify-center items-center my-2">
              {!waiting && (
                <Button
                  bgColour="danger/20 hover:bg-danger/40"
                  textColour="black"
                  className="items-center w-fit"
                  onClick={() => {
                    dispatch(getNotifications({ pageNum: page + 1 }));
                    setPage(page + 1);
                  }}
                >
                  <span className="flex items-center gap-x-1 text-black dark:text-white">
                    {generateTranslatedText("load_more_content", language)}
                  </span>
                </Button>
              )}
            </div>
          )}

        </div>
      </div>

    </Template>
  );
}
