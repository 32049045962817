import Container from "./Container";

interface HeaderProps {
  image?: string;
  text: string;
  metadata?: React.ReactNode;
  actions?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
}

export default function Header({
  image = "",
  text,
  metadata = null,
  actions = null,
  breadcrumbs = null,
}: HeaderProps) {
  return (
    <Container>
      <div className="lg:flex lg:items-start lg:justify-between lg:pb-8 pb-6 pt-2 border-b border-light/20">
        <div className="min-w-0 flex-1">
          {/* Breadcrumb */}
          {breadcrumbs !== null && <>{breadcrumbs}</>}

          {/* Image */}
          {image !== "" && (
            <div className="py-2 mt-2">
              <img
                src={image}
                alt=""
                className="w-32 h-32 sm:w-40 sm:h-40 rounded-full border-2 border-black object-cover"
              />
            </div>
          )}

          {/* Title */}
          <h2
            className={`${breadcrumbs !== null && "mt-2"} text-3xl font-bold leading-7 text-black dark:text-white sm:truncate sm:tracking-tight`}
          >
            {text}
          </h2>

          {/* Metadata */}
          {metadata !== null && <>{metadata}</>}
        </div>

        {/* Actions */}
        {actions !== null && <>{actions}</>}
      </div>
    </Container>
  );
}