interface NoContentProps {
  text: string;
  subtext: string;
  icon?: JSX.Element | null;
}

export default function NoContent({ text, subtext, icon = null }: NoContentProps) {
  return (
    <div className="text-center">
      {icon}
      <h3 className="mt-2 text-sm font-semibold text-black dark:text-white">{text}</h3>
      <p className="mt-1 text-sm text-black dark:text-light">{subtext}</p>
    </div>
  );
}