import Template from "../app/Template";
import CreateOrSearchHeader from "./CreateOrSearchHeader";
import TopGroups from "./TopGroups";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getTopVotedPosts, PostState } from "../../slices/content";
import { useSearchParams } from "react-router-dom";
import LoadingBubbles from "../layout/LoadingBubbles";
import LandingFilters from "./LandingFilters";
import FeedFilmItem from "../layout/FeedFilmItem";
import Button from "../layout/Button";
import { generateTranslatedText } from "../../utils/boilerplate";
import MobileBottomToolbar from "./MobileBottomToolbar";
import { useAds } from "../contexts/AdProvider";
import BannerAds from "../advertisement/BannerAds";
import SwitchToggle from "../forms/SwitchToggle";
import { ChatBubbleLeftRightIcon, Squares2X2Icon } from "@heroicons/react/24/solid";

export default function TopPosts() {
  const dispatch = useAppDispatch();

  // Get query params
  const [searchParams] = useSearchParams();
  const querySearch = searchParams.get("query");

  // Get app level contexts
  const { banner: { data: bannerAdsData, loading: bannerAdsLoading } } = useAds();

  // Get app level state from redux store
  const { loading: authLoading } = useAppSelector((state) => state.auth);
  const { posts: { data, loading, waiting, pagination } } = useAppSelector((state) => state.content);

  // On page load
  useEffect(() => {
    if (!authLoading) {
      dispatch(getTopVotedPosts({ pageNumber: 1, query: querySearch }));
    }
  }, [dispatch, querySearch, authLoading]);

  // Get component level state
  const [page, setPage] = useState(1);
  const [_, setShowSearchModal] = useState(false);

  // Functions
  async function fetchTopVotedPostsByPageAndQuery(e: any, args: any[]) {
    e.preventDefault();
    const [pageNumber, query] = args;
    dispatch(getTopVotedPosts({ pageNumber, query }));
    setShowSearchModal(false);
  }

  const language = "EN";
  return (
    <Template showSearchInNavbar={true}>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 md:mt-16">

        {/* Posts content */}
        <div className="col-span-3">

          {/* Banner Ad */}
          {bannerAdsLoading ? (
            <div className="flex justify-center items-center w-full my-16">
              <LoadingBubbles />
            </div>
          ) : bannerAdsData.length === 0 ? null : (
            <BannerAds ads={bannerAdsData} />
          )}

          {/* Create post or search */}
          <CreateOrSearchHeader
            searchHandler={fetchTopVotedPostsByPageAndQuery}
            searchHandlerArgs={[page]}
            loading={loading}
          />

          {/* Filter buttons */}
          {/* <LandingFilters page="/top" /> */}
          {/* Switch to discussion */}
          <div className="w-full flex gap-x-4 justify-center items-center my-4">
            <div className="flex justify-center items-center gap-x-2">
              <p className="hidden xs:block text-sm font-semibold text-black dark:text-white">Grid</p>
              <SwitchToggle
                id="feed-type"
                label=""
                showLabel={false}
                checked={window.location.pathname.includes("/top")}
                onChange={(e) => {
                  window.location.href = "/grid";
                }}
                icon={window.location.pathname.includes("/grid") ? <Squares2X2Icon className="h-3 w-3 text-black" /> : <ChatBubbleLeftRightIcon className="h-3 w-3 text-black" />}
              />
              <p className="hidden xs:block text-sm font-semibold text-black dark:text-white">Discussion</p>
            </div>

            <div className="flex justify-end items-center gap-x-2">
              <a href="/new" className="text-sm px-4 py-1 text-black dark:text-white bg-black/5 hover:bg-black/30 dark:bg-white/5 dark:hover:bg-white/30 rounded-full">
                <span className="text-xs font-semibold">New</span>
              </a>
              <a href="#!" className="text-sm px-4 py-1 text-black dark:text-white bg-black/20 hover:bg-black/30 dark:bg-white/20 dark:hover:bg-white/30 rounded-full">
                <span className="text-xs font-semibold">Top</span>
              </a>
              <a href="/trending" className="text-sm px-4 py-1 text-black dark:text-white bg-black/5 hover:bg-black/30 dark:bg-white/5 dark:hover:bg-white/30 rounded-full">
                <span className="text-xs font-semibold">Trending</span>
              </a>
            </div>
          </div>

          {/* Feed */}
          {loading ? (
            <div className="flex justify-center items-center w-full my-20">
              <LoadingBubbles />
            </div>
          ) : data.map((post: PostState) => (
            <FeedFilmItem post={post} key={post.id} hideLongText={true} />
          ))}

          {/* Load more content */}
          {pagination.has_next && (
            <div className="flex justify-center items-center my-10">
              {waiting ? (
                <div className="flex justify-center items-center w-full my-20">
                  <LoadingBubbles />
                </div>
              ) : (
                <Button
                  bgColour="danger/20 hover:bg-danger/40"
                  textColour="black"
                  className="items-center w-fit"
                  onClick={() => {
                    dispatch(getTopVotedPosts({ pageNumber: page + 1, query: querySearch }))
                    setPage(page + 1);
                  }}
                >
                  <span className="flex items-center gap-x-1 text-black dark:text-white">
                    {generateTranslatedText("load_more_content", language)}
                  </span>
                </Button>
              )}
            </div>
          )}

        </div>

        {/* Groups content */}
        {/* <div className="col-span-1">
          <TopGroups />
        </div> */}

      </div>

      {/* Mobile toolbar */}
      <MobileBottomToolbar pageNumber={page} pageName="top" loading={loading} />

    </Template>
  );
}
