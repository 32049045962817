import { PencilSquareIcon, QuestionMarkCircleIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { generateTranslatedText } from "../../utils/boilerplate";
import SearchInputWithIcon from "../forms/SearchInputWithIcon";
import Button from "../layout/Button";
import Card from "../layout/Card";
import { useState } from "react";
import Modal from "../layout/Modal";
import { Dialog } from "@headlessui/react";
import TextInput from "../forms/TextInput";
import TextArea from "../forms/TextArea";
import UploadImageDropzone from "../forms/UploadImageDropzone";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { addAlert } from "../../slices/alert";
import { resetImageUploads } from "../../slices/uploads";
import { createPost } from "../../slices/content";
import Dropdown from "../forms/Dropdown";
import { createGroup, getInterestGroupCategories } from "../../slices/interest";
import LoadingBubbles from "../layout/LoadingBubbles";

export default function CreateOrSearchHeader({
  searchHandler,
  searchHandlerArgs,
  loading,
  showCreatePostButton = true,
  showCreateQuestionButton = true,
  showCreateGroupButton = true,
  groupSlug = null
}: {
  searchHandler: (e: any, args: any[]) => void;
  searchHandlerArgs: any[];
  loading: boolean;
  showCreatePostButton?: boolean;
  showCreateQuestionButton?: boolean;
  showCreateGroupButton?: boolean;
  groupSlug?: string | null;
}) {

  const dispatch = useAppDispatch();

  // Get app level state from redux store
  const { user, isAuthenticated } = useAppSelector((state) => state.auth);
  const { images } = useAppSelector((state) => state.uploads);
  const { groups: { options: groupsOptions, loading: groupsLoading } } = useAppSelector((state) => state.interest);

  // Component level state
  const [showCreatePost, setShowCreatePost] = useState(false);
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [createPostContent, setCreatePostContent] = useState({
    placeholder: "",
    group: "",
    title: "",
    body: "",
    image: {
      source: "",
      data: ""
    },
    type: "post"
  });
  const [createGroupContent, setCreateGroupContent] = useState<{
    name: string;
    description: string;
    category: string;
    subcategory: string[];
    profile_picture: string | null;
    cover_picture: string | null;
  }>({
    name: "",
    description: "",
    category: "",
    subcategory: [],
    profile_picture: null,
    cover_picture: null,
  });

  // Functions
  async function confirmCreatePost(e: any) {
    e.preventDefault();
    if (!isAuthenticated || !user) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_post", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return
    }
    const { title, body, group } = createPostContent;
    if (title === "") {
      dispatch(addAlert(generateTranslatedText("missing_title_post", language), "warning"));
      return
    }
    if (body === "" && createPostContent.type === "post") {
      dispatch(addAlert(generateTranslatedText("missing_body_post", language), "warning"));
      return
    }
    // Get processed images from redux store
    let imageData: any = null;
    const processedImages = Object.values(images.data).filter((img) => img.success);
    if (processedImages.length > 0) {
      imageData = {
        id: processedImages[0].id,
        source: "processed",
        data: processedImages[0].data,
      };
    }
    // Dispatch action
    setShowCreatePost(false);
    const res: any = await dispatch(createPost({
      title,
      body,
      image: imageData,
      group: groupSlug !== null ? groupSlug : group === "" ? null : group,
    }))
    if (res.payload.status === 201) {
      dispatch(resetImageUploads());
      dispatch(addAlert(generateTranslatedText("post_created", language), "success"));
    } else {
      setShowCreatePost(true);
      dispatch(addAlert(generateTranslatedText("post_created_error", language), "warning"));
    }
  }

  async function confirmCreateGroup(e: any) {
    e.preventDefault();

    if (!isAuthenticated || !user) {
      dispatch(addAlert(`${generateTranslatedText("if_you_want_to_create_group", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
      return
    }

    const {
      name,
      description,
      category,
      subcategory,
    } = createGroupContent;
    if (name === "") {
      dispatch(addAlert(generateTranslatedText("missing_name_group", language), "warning"));
      return
    }
    if (description === "") {
      dispatch(addAlert(generateTranslatedText("missing_description_group", language), "warning"));
      return
    }

    // Get processed images from redux store
    let imageData: any = { cover: null, profile: null };
    if ("group-profile-picture" in images.data) {
      imageData.profile = {
        id: "group-profile-picture",
        source: "processed",
        data: images.data["group-profile-picture"].data,
      };
    }
    if ("group-cover-picture" in images.data) {
      imageData.cover = {
        id: "group-cover-picture",
        source: "processed",
        data: images.data["group-cover-picture"].data,
      };
    }

    // Dispatch action
    setShowCreateGroup(false);
    const res: any = await dispatch(createGroup({
      administrators: [user.profile.id],
      name,
      description,
      category: category === "" ? null : category,
      subcategory,
      profile_picture: imageData.profile,
      cover_picture: imageData.cover,
      status: "A"
    }))
    if (res.payload.status === 201) {
      dispatch(resetImageUploads());
      dispatch(addAlert(generateTranslatedText("group_created", language), "success"));
      const groupSlug = res.payload.data.slug;
      window.location.href = `/group/${groupSlug}`;
    } else {
      setShowCreateGroup(true);
      dispatch(addAlert(generateTranslatedText("group_created_error", language), "warning"));
    }
  }

  // Constant variables
  const language = "EN";
  const dropdownOptionsCategory = groupsOptions.category === undefined ? [] : groupsOptions.category.map((opt) => [opt.id, opt.name]);
  return (
    <Card className="w-full hidden sm:block">
      <div className="grid grid-cols-1 gap-4">

        {/* Create post modal */}
        <Modal
          show={showCreatePost}
          setShow={setShowCreatePost}
          confirmText={generateTranslatedText("create", language)}
          cancelText={generateTranslatedText("close", language)}
          confirmHandler={confirmCreatePost}
          showActionButtons={true}
          showTriggerButton={true}
        >
          <div className="overflow-auto px-1">
            <div className="text-center">
              <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-black dark:text-white">
                {createPostContent.type === "post" ? generateTranslatedText("create_a_post", language) : generateTranslatedText("ask_a_question", language)}
              </Dialog.Title>
              <div className="my-10 grid grid-cols-1 gap-y-8">

                {/* Group */}
                {groupSlug === null && (
                  <Dropdown
                    id="post-group-dropdown"
                    label=""
                    showLabel={false}
                    required={false}
                    onChange={(e) => setCreatePostContent({ ...createPostContent, group: e.target.value })}
                    value={createPostContent.group}
                  >
                    <option value="">Which group do you want to post in?</option>
                    {user?.profile.groups.map((opt, idx) => (
                      <option key={idx} value={opt[0]}>{opt[1]}</option>
                    ))}
                  </Dropdown>
                )}

                {/* Title */}
                <TextInput
                  id="post-title"
                  type="text"
                  placeholder={createPostContent.type === "question" ? generateTranslatedText("ask_a_question", language) : generateTranslatedText("title", language)}
                  onChange={(e) => setCreatePostContent({ ...createPostContent, title: e.target.value })}
                  required={true}
                  showLabel={false}
                  widthClass="w-full"
                  value={createPostContent.title}
                />

                {/* Body */}
                {createPostContent.type === "post" && (
                  <TextArea
                    id="post-title"
                    placeholder={createPostContent.placeholder}
                    onChange={(e) => setCreatePostContent({ ...createPostContent, body: e.target.value })}
                    required={true}
                    showLabel={false}
                    widthClass="w-full"
                    rows={5}
                    value={createPostContent.body}
                  />
                )}

                {/* Image upload */}
                <UploadImageDropzone
                  id="post-image"
                  className="w-full"
                  limit={1}
                  savedImages={[]}
                />

              </div>
            </div>
          </div>
        </Modal>

        {/* Create group modal */}
        <Modal
          show={showCreateGroup}
          setShow={setShowCreateGroup}
          confirmText={generateTranslatedText("create", language)}
          cancelText={generateTranslatedText("close", language)}
          confirmHandler={confirmCreateGroup}
          showActionButtons={true}
          showTriggerButton={true}
        >
          <div className="overflow-auto px-1">
            <div className="text-center">
              <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-white">
                {generateTranslatedText("create_a_group", language)}
              </Dialog.Title>
              <div className="my-10 grid grid-cols-1 gap-y-8">

                {/* Name */}
                <TextInput
                  id="group-name"
                  type="text"
                  placeholder={generateTranslatedText("name", language)}
                  onChange={(e) => setCreateGroupContent({ ...createGroupContent, name: e.target.value })}
                  required={true}
                  showLabel={false}
                  widthClass="w-full"
                  value={createGroupContent.name}
                />

                {/* Description */}
                <TextArea
                  id="group-description"
                  placeholder={generateTranslatedText("description", language)}
                  onChange={(e) => setCreateGroupContent({ ...createGroupContent, description: e.target.value })}
                  required={true}
                  showLabel={false}
                  widthClass="w-full"
                  rows={3}
                  value={createGroupContent.description}
                />

                {/* Category */}
                {groupsLoading ? (
                  <div className="flex justify-center">
                    <LoadingBubbles />
                  </div>
                ) : (
                  <Dropdown
                    id="group-category-dropdown"
                    label={generateTranslatedText("category", language)}
                    showLabel={false}
                    required={true}
                    onChange={(e) => setCreateGroupContent({ ...createGroupContent, category: e.target.value })}
                    value={createGroupContent.category}
                  >
                    <option value="">Select a category</option>
                    {dropdownOptionsCategory.map((opt, idx) => (
                      <option key={idx} value={opt[0]}>{opt[1]}</option>
                    ))}
                  </Dropdown>
                )}

                {/* Profile picture */}
                <UploadImageDropzone
                  id="group-profile-picture"
                  className="w-full"
                  limit={1}
                  label={generateTranslatedText("profile_picture", language)}
                  savedImages={[]}
                />

                {/* Cover picture */}
                <UploadImageDropzone
                  id="group-cover-picture"
                  className="w-full"
                  limit={1}
                  label={generateTranslatedText("cover_picture", language)}
                  savedImages={[]}
                />

              </div>
            </div>
          </div>
        </Modal>

        {/* Search for posts */}
        {/* <SearchInputWithIcon
          id="search-posts"
          name="search-posts"
          placeholder={generateTranslatedText("search_topics", language)}
          widthClass="w-full"
          onSubmit={searchHandler}
          onSubmitArgs={searchHandlerArgs}
          loading={loading}
        /> */}

        {/* Action buttons */}
        <div className="flex flex-row justify-around gap-4">

          {/* Create post button */}
          {showCreatePostButton && (
            <Button
              bgColour="transparent hover:bg-light/30 dark:hover:bg-black"
              onClick={() => {
                if (!isAuthenticated || !user) {
                  dispatch(addAlert(`${generateTranslatedText("if_you_want_to_post", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
                  return
                }
                dispatch(resetImageUploads())
                setCreatePostContent({
                  group: "",
                  title: "",
                  placeholder: generateTranslatedText("create_post_placeholder", language),
                  body: "",
                  image: {
                    source: "",
                    data: ""
                  },
                  type: "post"
                })
                setShowCreatePost(true)
              }}
            >
              <span className="flex items-center gap-x-2">
                <PencilSquareIcon className="h-5 w-5 text-danger shrink-0" />
                {generateTranslatedText("create_post", language)}
              </span>
            </Button>
          )}

          {/* Ask a question button */}
          {showCreateQuestionButton && (
            <Button
              bgColour="transparent hover:bg-light/30 dark:hover:bg-black"
              onClick={() => {
                if (!isAuthenticated || !user) {
                  dispatch(addAlert(`${generateTranslatedText("if_you_want_to_post", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
                  return
                }
                dispatch(resetImageUploads())
                setCreatePostContent({
                  group: "",
                  title: "",
                  placeholder: generateTranslatedText("create_question_placeholder", language),
                  body: "",
                  image: {
                    source: "",
                    data: ""
                  },
                  type: "question"
                })
                setShowCreatePost(true)
              }
              }
            >
              <span className="flex items-center gap-x-2">
                <QuestionMarkCircleIcon className="h-5 w-5 text-danger shrink-0" />
                {generateTranslatedText("ask_a_question", language)}
              </span>
            </Button>
          )}

          {/* Create a group */}
          {showCreateGroupButton && (
            <Button
              bgColour="transparent hover:bg-light/30 dark:hover:bg-black"
              onClick={() => {
                if (!isAuthenticated || !user) {
                  dispatch(addAlert(`${generateTranslatedText("if_you_want_to_create_group", language)} ${generateTranslatedText("please_log_in", language).toLowerCase()}`, "warning", 5000));
                  return
                }
                dispatch(getInterestGroupCategories())
                dispatch(resetImageUploads())
                setCreateGroupContent({
                  name: "",
                  description: "",
                  category: "",
                  subcategory: [],
                  profile_picture: null,
                  cover_picture: null,
                })
                setShowCreateGroup(true)
              }}
            >
              <span className="flex items-center gap-x-2">
                <UserGroupIcon className="h-5 w-5 text-danger shrink-0" />
                {generateTranslatedText("create_group", language)}
              </span>
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
}
