import { Squares2X2Icon } from "@heroicons/react/24/solid";

const NativeShareButton = ({
  title,
  text,
  url
}: {
  title: string;
  text: string;
  url: string;
}) => {

  const shareData = {
    title,
    text,
    url,
  };

  const handleNativeShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share(shareData);
        console.log('Page shared successfully');
      } else {
        alert('Native share is not supported on your browser.');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <button className="rounded bg-black border border-black dark:border-white text-white dark:bg-white dark:text-blacl px-5 py-2.5 text-xs font-medium" onClick={handleNativeShare}>
      <span className="flex gap-x-1 items-center">
        <Squares2X2Icon className="w-5 h-5" />
        Other apps...
      </span>
    </button>
  );
};

export default NativeShareButton;