import { Helmet } from "react-helmet-async";
import Template from "../app/Template";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { CommentState, getPostBySlug } from "../../slices/content";
import { useParams, useSearchParams } from "react-router-dom";
import LoadingBubbles from "../layout/LoadingBubbles";
import FeedFilmItem from "../layout/FeedFilmItem";

export default function PostBySlug() {
  const dispatch = useAppDispatch();

  // Get query params
  const [searchParams] = useSearchParams();
  const queryComment = searchParams.get("comment");

  // Get URL params
  const { post_slug: postSlug } = useParams()

  // On page load
  useEffect(() => {
    dispatch(getPostBySlug({ postSlug }));
  }, [dispatch, postSlug]);

  // Get app level state from redux store
  const {
    post: {
      data: postData,
      loading: postLoading
    },
    comments: {
      data: commentsData,
      loading: commentsLoading
    }
  } = useAppSelector((state) => state.content);

  useEffect(() => {
    if (!postLoading && !commentsLoading && postData !== null) {
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.text = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "DiscussionForumPosting",
        "headline": postData.title,
        "text": postData.body,
        "author": {
          "@type": "Person",
          "name": postData.created_by_ref,
          "url": `https://filmwaffle.com/profile/${postData.created_by_id}`
        },
        "datePublished": postData.created_date_iso,
        "url": `https://filmwaffle.com/post/${postData.slug}`,
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": `https://filmwaffle.com/post/${postData.slug}`
        },
        "commentCount": postData.num_comments,
        "upvoteCount": postData.votes.upvotes,
        "comment": commentsData.length === 0 ? [] : commentsData.map((comment: CommentState) => ({
          "@type": "Comment",
          "author": {
            "@type": "Person",
            "name": comment.created_by_ref,
            "url": `https://filmwaffle.com/profile/${comment.created_by_id}`
          },
          "dateCreated": comment.created_date_iso,
          "text": comment.body
        })),
      });
      document.head.appendChild(script);

      return () => {
        // Cleanup: Remove the script when the component unmounts or `loading` changes
        document.head.removeChild(script);
      };
    }
  }, [postLoading, commentsLoading]);

  useEffect(() => {
    if (!postLoading && postData !== null) {
      document.title = `FilmWaffle - ${postData.title}`; // Set document title

      // Cleanup (restore default title when unmounting)
      return () => {
        document.title = "FilmWaffle - The Hub For Film Lovers";
      };
    }
  }, [postLoading, postData]);

  return (
    <Template>

      <Helmet>
        <title>FilmWaffle - {`${postData?.title}`}</title>
        <meta name="description" content={`${postData?.body}` || "Join the discussion on FilmWaffle"} />
      </Helmet>

      <div className="grid grid-cols-1 gap-4 my-16">

        {/* Posts content */}
        <div>

          {/* Feed */}
          {postLoading ? (
            <div className="flex justify-center items-center w-full my-20">
              <LoadingBubbles />
            </div>
          ) : postData === null ? <p>404</p> : (
            <div className="rounded-md bg-white dark:bg-black-light p-4">
              <FeedFilmItem post={postData} key={postData.id} showFirstPageOfComments={true} highlightedComment={queryComment} />
            </div>
          )}

        </div>
      </div>

    </Template>
  );
}
